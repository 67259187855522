import Meta from "@/router/meta";
import store from "@/store";
import { brandName } from "@/configs/ourApp.config";

const META_SEPARATOR = " / ";

export default (to, from) => {
  // Meta data service
  Meta.setDocumentMeta(META_SEPARATOR, brandName, to, from);

  store.commit("loader/SET_RENDERING_FINISHED");
};
