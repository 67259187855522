<template>
  <div class="form">
    <OurGroup class="form-group">
      <OurInput
        v-model="form.id"
        :label="$t('label.id')"
        :placeholder="$t('placeholder.caseNumber')"
      />

      <OurInput
        v-model="form.email"
        :label="$t('label.email')"
        :placeholder="$t('placeholder.email')"
      />

      <OurPhoneInput
        v-model="form.phone"
        :label="$t('label.phoneNumber')"
        :placeholder="$t('placeholder.phone')"
        :flags="false"
        dropdown-disabled
      />

      <PaginationSelect
        v-model="form.id"
        :options="managers"
        :options-limit="managers?.length"
        :label="$t('label.manager')"
        :placeholder="$t('placeholder.manager')"
      />

      <PaginationSelect
        v-model="form.companyId"
        :options="companies"
        :options-limit="companies?.length"
        :label="$t('label.company')"
        :placeholder="$t('placeholder.selectCompany')"
      />

      <PaginationSelect
        v-model="form.customerId"
        :options-limit="customers?.length"
        :options="customers"
        :label="$t('label.customer')"
        :placeholder="$t('placeholder.selectCustomer')"
      />

      <OurSelect
        v-model="form.isActive"
        :label="$t('label.status')"
        :placeholder="$t('placeholder.selectStatus')"
        :options="getStatuses"
        item-label="text"
        value-key="value"
      />
    </OurGroup>
  </div>
</template>

<script>
import PaginationSelect from "@/ui/select/PaginationSelect.vue";
import { mapGetters, mapState } from "vuex";

export default {
  name: "FilterForm",

  components: {
    PaginationSelect,
  },

  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      form: {
        email: "",
        phone: "",
        id: "",
        customerId: "",
        companyId: "",
        isActive: "",
      },
    };
  },

  computed: {
    ...mapState("dealerUsers", ["customers"]),
    ...mapState("managers", ["managers", "companies", "customers"]),
    ...mapGetters("user", ["isUserDealerAdmin", "isUserDealer"]),
    ...mapGetters("managers", ["getStatuses"]),
  },

  watch: {
    value: {
      handler: "setForm",
      deep: true,
      immediate: true,
    },

    form: {
      handler: "onChangeForm",
      deep: true,
    },
  },

  created() {
    if (this.isUserDealer) {
      this.form.customerId = this.customers && this.customers[0]?.id;
    }
  },

  methods: {
    setForm() {
      this.form = this.value;
    },

    onChangeForm() {
      this.$emit("input", this.form);
    },
  },
};
</script>
