import i18n from "@/plugins/vue-i18n";

export default {
  namespaced: true,
  state: {
    status: {
      activated: {
        text: i18n.t("dealerUsers.status.activated"),
        color: "green",
        value: true,
      },
      deactivated: {
        text: i18n.t("dealerUsers.status.deactivated"),
        color: "gray",
        value: false,
      },
    },
    filtersForm: null,
    managers: [],
    companies: [],
    customers: [],
  },

  getters: {
    getStatuses(state) {
      return Object.values(state.status);
    },
    getStatus(state) {
      return (isActive) => {
        if (isActive) {
          return state.status.activated;
        } else {
          return state.status.deactivated;
        }
      };
    },
    getFiltersOption() {
      return (column, value) => {
        const columns = ["phone", "email"];
        const operator = columns.includes(column) ? "like" : "=";
        const option = {
          method: "where",
          column,
          operator,
          value,
        };

        if (column === "companyId") {
          option.relation = "companies";
          option.column = "id";
        }

        if (column === "customerId") {
          option.relation = "companies";
          option.column = "customerId";
        }

        return option;
      };
    },

    getFiltersOptions(state, getters) {
      return () => {
        const filtersForm = state.filtersForm;
        let filtersOptions = [];

        if (filtersForm) {
          Object.keys(filtersForm).forEach((key) => {
            if (filtersForm[key]) {
              const column = key;
              const value = filtersForm[key];

              filtersOptions.push(getters.getFiltersOption(column, value));
            }
          });
        }

        return filtersOptions;
      };
    },
  },
  mutations: {
    SET_MANAGERS(state, managers) {
      if (managers) {
        state.managers = managers.map((manager) => {
          const { id, name: label } = manager;

          return { id, label };
        });
      }
    },
    SET_COMPANIES(state, companies) {
      state.companies = companies.map((company) => {
        const { id, name: label } = company;

        return { id, label };
      });
    },
    SET_CUSTOMERS(state, customers) {
      if (customers) {
        state.customers = customers.map((customer) => {
          const { id, name: label } = customer;

          return { id, label };
        });
      }
    },
    SET_FILTERS_FORM(state, form) {
      state.filtersForm = form;
    },
  },
};
