import gql from "graphql-tag";

export const noticeAndCategoriesQuery = gql`
  query notice($id: ID!) {
    notice(id: $id) {
      name
      description
      category {
        id
      }
      currency {
        symbol
      }
      price
      quantity
      location
      isNew
      moderationStatus
      condition
      contactName
      contactPhone
      isActive
      isOwn
      files {
        link
      }
    }
    productCategories {
      id
      description
      parent
    }
  }
`;
