import gql from "graphql-tag";

export const rolesQuery = gql`
  query roles {
    roles {
      id
      name
      code
      parent {
        code
      }
    }
  }
`;

export const employeesQuery = gql`
  query employees($filters: Filter, $page: Int, $perPage: Int) {
    employees(
      filters: $filters
      orderBy: { column: "createdAt", direction: desc }
      page: $page
      first: $perPage
    ) {
      data {
        id
        phone
        email
        firstName
        lastName
        secondName
        lastActivityAt
        roles {
          name
        }
        detail {
          ... on Employee {
            status
          }
        }
      }
      paginatorInfo {
        currentPage
        perPage
        total
        lastPage
      }
    }
  }
`;
