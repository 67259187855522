import ApiService from "@/services/api.service";

const USER_TYPE = {
  dealer: "dealer",
  employee: "employee",
};

async function getProfile() {
  const response = await ApiService.get("/auth/profile");

  return response.data;
}

async function setVideoClosed(data) {
  return ApiService.post("/events/video-closed", data);
}

async function wizardViewed(data) {
  return ApiService.post("/events/wizard-viewed", data);
}

export { USER_TYPE, getProfile, setVideoClosed, wizardViewed };
