<template>
  <OurPage data-cy="users-page" :title="$t('title.customers._')" gray class="users-page">
    <CustomersList
      :items="customers"
      :pagination-data="paginationData"
      @pageChange="getData"
      @onClickRow="onClickRow"
    />

    <FilterModal :show.sync="isShownFilterModal" />

    <template #header-right>
      <div class="buttons">
        <OurButton
          variant="thirdary"
          filled
          :text="$t('button.filters')"
          @click="onClickFilterButton"
        >
          <OurSvgIcon v-if="isMobileDevice" :src="icons.FilterAltFill" />
        </OurButton>

        <OurLink v-if="getPermission['customer.create']" :route="addRoute">
          <OurButton :text="$t('button.add')" />
        </OurLink>
      </div>
    </template>
  </OurPage>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import apolloMixin from "@/mixins/apollo.mixin";
import { accessGroupsQuery, customersQuery, allCustomersQuery } from "./gql/queries";
import CustomersList from "@/views/settings/customers/index/_components/CustomersList.vue";
import FilterModal from "@/views/settings/customers/filter/Modal.vue";
import permissionsMixin from "@/mixins/permissions.mixin";

export default {
  name: "Index",

  components: { FilterModal, CustomersList },

  mixins: [apolloMixin, permissionsMixin],

  data() {
    return {
      customers: [],
      paginationData: {},
      isShownFilterModal: false,
    };
  },

  computed: {
    ...mapState("customers", ["filtersForm"]),
    ...mapGetters("breakpoint", ["isMobileDevice"]),
    ...mapGetters("user", ["userProfile", "isUserDealer", "isUserDealerAdmin", "getPermission"]),
    ...mapGetters("customers", ["getStatus", "getFiltersOptions"]),

    addRoute() {
      return { name: "CustomersAdd" };
    },

    icons: () => ({
      FilterAltFill: require("@material-symbols/svg-400/outlined/filter_alt-fill.svg"),
    }),
  },

  watch: {
    filtersForm: {
      handler: "onChangeFiltersForm",
      deep: true,
    },
  },

  async created() {
    await this.getAllCustomers();
    await this.getAccessGroupsAndManagers();
  },

  methods: {
    ...mapMutations("dealerCompany", ["SET_ACCESS_GROUPS"]),
    ...mapMutations("dealerUsers", ["SET_CUSTOMERS"]),

    async getAccessGroupsAndManagers() {
      const { accessGroups } = await this.$get(accessGroupsQuery);

      if (accessGroups) {
        this.SET_ACCESS_GROUPS(accessGroups);
      }
    },

    async getAllCustomers() {
      const { allCustomer } = await this.$get(allCustomersQuery);

      this.SET_CUSTOMERS(allCustomer);
    },

    async getData(page) {
      if (page && page === this.paginationData.currentPage) return;

      const filtersOptions = this.getFiltersOptions();

      const { customers } = await this.$get(customersQuery, { page, filtersOptions });

      if (customers) {
        this.customers = customers.data.map((customer) => {
          const { id, name, isActive, companies, accessGroup, dealers, bonusProgramLevel } =
            customer;

          return {
            id,
            name,
            companies,
            accessGroup: accessGroup?.name,
            dealers,
            bonusProgramLevel: bonusProgramLevel?.name,
            status: this.getStatus(isActive ? "activated" : "deactivated"),
            page: "CustomersEdit",
          };
        });

        this.paginationData = customers.paginatorInfo;
      }
    },

    onClickFilterButton() {
      this.isShownFilterModal = true;
    },

    onChangeFiltersForm() {
      this.getData();
    },

    onClickRow(id) {
      this.$router.push({ name: "CustomersEdit", params: { id } });
    },
  },
};
</script>

<style lang="postcss" scoped>
.users-page {
  .buttons {
    @apply flex items-center justify-between gap-4 sm:justify-start;
  }
}
</style>
