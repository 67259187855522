<template>
  <div class="slider-preview">
    <div v-if="images.length > slidesPerView && direction !== 'vertical'">
      <div class="slider-preview-button-prev">
        <OurSvgIcon
          :src="icons.AngleLeft"
          size="xs"
          color="white"
          :interactive="isBeginning"
          @click="onClickPrev"
        />
      </div>

      <div class="slider-preview-button-next">
        <OurSvgIcon
          :src="icons.AngleRight"
          size="xs"
          color="white"
          :interactive="isEnd"
          @click="onClickNext"
        />
      </div>
    </div>

    <swiper
      ref="slider"
      :direction="direction"
      :slides-per-view="slidesPerView"
      :space-between="spaceBetween"
      :pagination="pagination"
      class="slider-content"
      :class="{ 'vertical-slider': direction === 'vertical' }"
    >
      <swiper-slide v-for="(image, index) in images" :key="index">
        <slot :item="image">
          <div class="slide-image" :class="getSlideImageClass(index)" @click="onSlideClick(index)">
            <img :src="image.link" alt="image" @error="errorImageHandler" />
          </div>
        </slot>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper-vue2";
import "swiper/swiper.min.css";

export default {
  name: "SliderPreview",

  components: {
    Swiper,
    SwiperSlide,
  },

  props: {
    images: {
      type: Array,
      default: () => [],
    },

    value: {
      type: Number,
      default: 0,
    },

    isMobileDevice: {
      type: Boolean,
      default: false,
    },

    direction: {
      type: String,
      default: "horizontal",
    },
  },

  data() {
    return {
      defaultSlidesPerView: 4,
      defaultMobileSlidesPerView: 5,
      spaceBetween: 8,
      pagination: true,
      swiper: {},
    };
  },

  computed: {
    activeSlideIndex: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    icons: () => ({
      AngleLeft: require("@/components/_bit/IconSvg/icons/Angle-left.svg"),
      AngleRight: require("@/components/_bit/IconSvg/icons/Angle-right.svg"),
    }),

    slidesPerView() {
      return this.isMobileDevice ? this.defaultMobileSlidesPerView : this.defaultSlidesPerView;
    },

    isBeginning() {
      return !this.swiper.isBeginning;
    },

    isEnd() {
      return !this.swiper.isEnd;
    },
  },

  mounted() {
    this.swiper = this.$refs.slider.$el.swiper;
  },

  methods: {
    errorImageHandler(event) {
      event.target.src = require("@/components/_bit/IconSvg/icons/Image.svg");
    },

    getSlideImageClass(index) {
      return index === this.activeSlideIndex ? "active-image" : "";
    },

    onSlideClick(index) {
      this.activeSlideIndex = index;
    },

    onClickPrev() {
      this.swiper.slidePrev();
    },

    onClickNext() {
      this.swiper.slideNext();
    },
  },
};
</script>

<style lang="postcss" scoped>
.vertical-slider:deep {
  min-height: 300px;
  .swiper-wrapper {
    display: unset;
  }
  .swiper-slide {
    height: 100% !important;
  }
}
.slider-preview {
  @apply relative;

  &-button-prev,
  &-button-next {
    @apply absolute top-5 z-10;
    @apply flex items-center justify-between;
    @apply h-4 w-4;
    @apply rounded bg-gray-400;
  }

  &-button-prev {
    @apply left-2.5;
  }

  &-button-next {
    @apply right-2.5;
  }

  .slide-image {
    @apply flex items-center justify-center rounded-lg border-2 border-solid border-gray-100 bg-cover bg-center;
    @apply h-[3.438rem] w-auto md:h-[3.5rem] md:w-[3.5rem];
    @apply p-[0.157rem];
    @apply cursor-pointer;
    img {
      @apply h-2/3 max-w-full;
    }
  }

  .active-image {
    @apply border-2 border-gray-200;
  }
}
</style>
