<script>
import { mapGetters } from "vuex";
import apolloMixin from "@/mixins/apollo.mixin";
import { bonusProgramLevelsQuery } from "./gql/queries";

export default {
  mixins: [apolloMixin],

  data() {
    return {
      bonusLevelsSections: [],
      backRoute: {
        name: "Bonuses",
        title: this.$t("title.bonuses._"),
      },
    };
  },

  computed: {
    ...mapGetters("user", ["getPermission"]),
  },

  created() {
    this.getData();
  },

  methods: {
    async getData() {
      const { bonusProgramLevels } = await this.$get(bonusProgramLevelsQuery);

      if (bonusProgramLevels) {
        this.bonusLevelsSections = bonusProgramLevels.map((bonusLevelsSection) => {
          const { id, name } = bonusLevelsSection;

          return {
            id,
            name: name.toUpperCase(),
          };
        });
      }
    },

    onClickEdit(id) {
      this.$router.push({ name: "BonusLevelsEdit", params: { id } });
    },
  },
};
</script>

<template>
  <OurPage
    :back-route="backRoute"
    class="access-groups"
    :title="$t('title.bonuses.levels')"
    width="md"
  >
    <OurTableList
      :is-draggable="false"
      :have-permission-edit="getPermission['bonus_program.bonus_program']"
      :list="bonusLevelsSections"
      @clickEdit="onClickEdit"
    />
  </OurPage>
</template>
