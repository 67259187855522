import i18n from "@/plugins/vue-i18n";

const BonusesProgram = () =>
  import(/* webpackChunkName: "base-knowledge" */ "@/views/bonuses-program/index");

const bonusesProgram = [
  {
    path: "/bonuses-program",
    name: "BonusesProgram",
    component: BonusesProgram,
    meta: {
      title: i18n.t("title.bonuses._"),
      wizardKey: "bonusProgram",
    },
  },
];

export default bonusesProgram;
