<template>
  <OurPage
    class="company-add"
    :title="$t('title.dealersCompany.edit')"
    :back-route="backRoute"
    width="md"
  >
    <DeleteModal
      :show.sync="isShownDeleteModal"
      :user-id="companyId"
      :full-name="form.title"
      :mutation="deleteDealerMutation()"
      @deleteUser="goToList"
    />

    <CompanyForm
      ref="companyForm"
      v-model="form"
      class="company-form"
      edit-form
      :groups="accessGroups"
      :dealers="dealers"
      @openModal="show = !show"
      @openDealersModal="openDealersModal"
      @openAddDealersModal="openAddDealersModal"
    />

    <DealersModal
      :dealers="dealers"
      :show.sync="isShownDealersModal"
      @addDealer="openAddDealersModal"
    />

    <AddDealersModal :show.sync="isShownAddDealersModal" @updateDealers="getData" />

    <AddAccessGroupModal :show.sync="show" @getData="getData" />

    <template #footer-left>
      <OurButton
        v-if="getPermission['company.update']"
        class="button"
        :text="$t('button.save')"
        @click="onClickSaveButton"
      />
      <OurButton
        class="button"
        :text="$t('button.back')"
        dashed
        variant="secondary"
        @click="$router.back()"
      />
    </template>
  </OurPage>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import apolloMixin from "@/mixins/apollo.mixin";
import { dealerCompanyQuery } from "./gql/queries";
import { updateDealerCompany } from "./gql/mutations";
import DeleteModal from "@/components/modals/DeleteModal.vue";
import { deleteDealerMutation } from "@/views/settings/dealer-users/delete/gql/mutations";
import CompanyForm from "@/views/settings/dealer-company/_components/CompanyForm.vue";
import { accessGroupsQuery } from "@/views/settings/dealer-company/add/gql/queries";
import AddAccessGroupModal from "@/views/settings/dealer-company/_components/AddAccessGroupModal.vue";
import DealersModal from "@/views/settings/dealer-company/_components/DealersModal.vue";
import AddDealersModal from "@/views/settings/dealer-company/_components/AddDealersModal.vue";

export default {
  name: "DealerCompanyEdit",

  components: {
    AddDealersModal,
    DealersModal,
    AddAccessGroupModal,
    CompanyForm,
    DeleteModal,
  },

  mixins: [apolloMixin],

  data() {
    return {
      isShownDeleteModal: false,
      isShownDealersModal: false,
      isShownAddDealersModal: false,
      isUserDeleted: false,
      backRoute: {
        name: "Back",
        title: this.$t("title.dealersCompany._"),
      },
      form: {
        title: "",
        taxNumber: "",
        accessGroupId: "",
        oneCId: "",
        managerName: "",
        isActive: false,
      },
      show: false,
      dealers: [],
    };
  },

  computed: {
    ...mapState("dealerCompany", ["accessGroups", "managers"]),
    ...mapGetters("dealerUsers", ["getStatus", "getRoleLocalization"]),
    ...mapGetters("user", ["isUserDealer", "getPermission"]),

    companyId() {
      return this.$route.params.id;
    },
  },

  created() {
    this.getData();
  },

  methods: {
    ...mapMutations("dealerCompany", ["SET_ACCESS_GROUPS", "SET_MANAGERS"]),

    deleteDealerMutation() {
      return deleteDealerMutation;
    },

    async getData() {
      const { accessGroups } = await this.$get(accessGroupsQuery);

      if (accessGroups) {
        this.SET_ACCESS_GROUPS(accessGroups);
      }

      const companyId = this.companyId;
      const { company } = await this.$get(dealerCompanyQuery, { companyId });

      if (company) {
        const { name, isActive, manager, accessGroup, taxNumber, oneCId } = company;

        this.form = {
          title: name,
          companyId,
          oneCId: oneCId,
          managerName: manager?.name,
          accessGroupId: accessGroup?.id,
          isActive,
          taxNumber,
        };
      }
    },

    async onClickSaveButton() {
      this.$refs.companyForm.emitForm();

      if (this.form.isValid) {
        const form = {
          ...this.form,
          accessGroupId: { connect: this.form.accessGroupId ? this.form.accessGroupId : "" },
        };

        const { updateCompany } = await this.$post(updateDealerCompany, form, {
          delaySuccessNotify: true,
        });

        if (updateCompany.id) this.goToList();
      }
    },

    onClickDeleteButton() {
      this.isShownDeleteModal = true;
    },

    goToList() {
      this.$router.back();
    },

    openDealersModal() {
      this.isShownDealersModal = true;
    },

    openAddDealersModal() {
      this.isShownAddDealersModal = true;
    },
  },
};
</script>

<style lang="postcss" scoped>
.company-add {
  @apply relative;

  .company-form {
    @apply mb-6;
  }
}
</style>
