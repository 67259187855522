import i18n from "@/plugins/vue-i18n";

import Bonuses from "@/views/settings/bonuses/index";
import BonusLevels from "@/views/settings/bonuses/levels/index";
import BonusProgram from "@/views/settings/bonuses/program/index";
import BonusProgramEdit from "@/views/settings/bonuses/program/edit";
import BonusLevelsEdit from "@/views/settings/bonuses/levels/edit";

const roles = [
  {
    path: "settings/bonuses",
    name: "Bonuses",
    component: Bonuses,
    meta: {
      title: i18n.t("title.bonuses._"),
    },
  },
  {
    path: "settings/bonuses/levels",
    name: "BonusLevels",
    component: BonusLevels,
    meta: {
      title: i18n.t("title.bonuses.levels"),
    },
  },
  {
    path: "settings/bonuses/program",
    name: "BonusProgram",
    component: BonusProgram,
    meta: {
      title: i18n.t("title.bonuses._"),
    },
  },
  {
    path: "settings/bonuses/program/:id/edit",
    name: "BonusProgramEdit",
    component: BonusProgramEdit,
    meta: {
      title: i18n.t("title.bonuses.editText"),
    },
  },
  {
    path: "settings/bonuses/levels/:id/edit",
    name: "BonusLevelsEdit",
    component: BonusLevelsEdit,
    meta: {
      title: i18n.t("title.bonuses.editLevel"),
    },
  },
];

export default roles;
