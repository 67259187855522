import gql from "graphql-tag";

export const bonusProgramLevelsQuery = gql`
  query bonusProgramLevels {
    bonusProgramLevels {
      id
      name
    }
  }
`;
