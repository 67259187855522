<template>
  <div class="form">
    <OurGroups>
      <OurGroup class="form-group" :title="$t('title.users.contact')">
        <OurInput
          v-model="form.lastName"
          :label="$t('label.lastName')"
          :placeholder="$t('placeholder.lastName')"
        />

        <OurInput
          v-model="form.firstName"
          :label="$t('label.name')"
          :placeholder="$t('placeholder.name')"
        />

        <OurInput
          v-model="form.email"
          :label="$t('label.email')"
          :placeholder="$t('placeholder.email')"
        />

        <OurInput
          v-model="form.id"
          :label="$t('label.id')"
          :placeholder="$t('placeholder.caseNumber')"
        />
      </OurGroup>

      <OurGroup class="form-group" :title="$t('title.users.details')">
        <OurSelect
          v-model="form.roleId"
          :options="roles"
          :label="$t('label.role')"
          :placeholder="$t('placeholder.selectRole')"
        />

        <OurSelect
          v-model="form.status"
          :label="$t('label.status')"
          :placeholder="$t('placeholder.selectStatus')"
          :options="getStatuses(isUserDealerAdmin)"
          item-label="text"
          value-key="value"
        />
      </OurGroup>
    </OurGroups>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "FilterForm",

  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      form: {
        lastName: "",
        firstName: "",
        email: "",
        id: "",
        roleId: "",
        status: "",
      },
    };
  },

  computed: {
    ...mapState("employeeUsers", ["roles"]),
    ...mapGetters("user", ["isUserDealerAdmin"]),
    ...mapGetters("employeeUsers", ["getStatuses"]),
  },

  watch: {
    value: {
      handler: "setForm",
      deep: true,
    },
  },

  mounted() {
    this.setForm();
  },

  methods: {
    setForm() {
      this.form = this.value;
    },
  },
};
</script>
