<template>
  <perfect-scrollbar class="perfect-scrollbar" :class="themeClass">
    <div class="menu-wrap" :class="{ '!overflow-y-visible': activeWizardKey }">
      <ul class="menu">
        <template v-for="(item, index) in items">
          <li
            v-if="item && !item.isHidden && checkPermissions(item)"
            :id="item.key"
            :key="index"
            :data-cy="`menu-link-${item.page}`"
            class="menu-item"
            :class="{ ...getMenuItemClasses(item), 'active-wizard': activeWizardKey === item?.key }"
          >
            <div v-if="!item.page && !item.link && item.isDivider" class="menu-item-link-line" />

            <div
              v-else-if="!item.page && !item.link"
              :key="`key-${index}`"
              class="menu-item-section"
            >
              <h5 class="menu-item-section-text">{{ $t(item.translate) }}</h5>
            </div>

            <div
              v-else-if="item.page === 'Products' && getPermission['catalog.categories']"
              class="menu-item-link-block cursor-pointer items-center"
              @click="clearCategory"
            >
              <div class="flex items-center">
                <h5 class="menu-item-link-text">{{ $t(item.translate) }}</h5>
              </div>
              <div class="category-arrow cursor-pointer items-center">
                <OurSvgIcon :src="icons.AngleRight" :class="{ 'rotate-90': showCategories }" />
              </div>
            </div>

            <OurLink
              v-else
              class="menu-item-link"
              :url="getUrl(item)"
              :target-blank="getTarget(item)"
              :route="getRoute(item)"
              no-focus-ring
              @click="onClickItem(item)"
            >
              <div class="menu-item-link-block items-center">
                <h5 class="menu-item-link-text" v-html="$t(item.translate)" />
              </div>
            </OurLink>
            <div v-if="categoriesLink" class="menu-item">
              <div v-if="categories.length && showCategories && index === 1" class="category-list">
                <CategoriesList :items="categories" @clickCategory="onClickCategory" />
              </div>
            </div>
          </li>
        </template>
      </ul>
    </div>
  </perfect-scrollbar>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { getThemeClass, getBasePath } from "@/services/_bit/UiService";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import layoutMixin from "./mixins";
import permissionsMixin from "@/mixins/permissions.mixin";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import OurLink from "@/components/_bit/ButtonLink";
import OurSvgIcon from "@/components/_bit/IconSvg";
import CategoriesDropdown from "@/components/CategoriesDropdown.vue";
import CategoriesList from "@/views/dashboard/_components/CategoriesList.vue";
import { dashboardDataQuery } from "@/views/dashboard/gql/queries";
import apolloMixin from "@/mixins/apollo.mixin";
import DateService from "@/services/date.service";

export default {
  name: "MainSubMenu",

  components: {
    CategoriesList,
    CategoriesDropdown,
    PerfectScrollbar,
    OurLink,
    OurSvgIcon,
  },

  mixins: [layoutMixin, apolloMixin, permissionsMixin],

  props: {
    items: {
      type: Array,
      required: true,
    },

    showIcon: {
      type: Boolean,
      default: false,
    },

    iconColor: {
      type: String,
      default: "gray",
    },

    activeItem: {
      type: Boolean,
      default: false,
    },
    categoriesLink: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      categories: [],
      showCategories: false,
      perPage: 3,
      page: 1,
    };
  },

  computed: {
    ...mapState("layout", ["isAnimation"]),

    ...mapGetters("breakpoint", ["isTabletDevice", "isMobileDevice"]),
    ...mapGetters("user", ["getPermission", "activeWizardKey"]),

    themeClass() {
      return getThemeClass();
    },

    icons: () => ({
      AngleRight: require("@/components/_bit/IconSvg/icons/Angle-right.svg"),
    }),
  },

  watch: {
    $route: {
      handler: function (to) {
        this.CLOSE_MOBILE_SUB_BLOCK();

        to.name === "Products" ? (this.showCategories = true) : (this.showCategories = false);
      },
    },
  },

  mounted() {
    this.getData();
  },

  methods: {
    ...mapMutations("layout", [
      "ENABLE_ANIMATION",
      "CLOSE_ASIDE_SUB_BLOCK",
      "CLOSE_MOBILE_SUB_BLOCK",
    ]),
    ...mapMutations("product", ["SET_CATEGORY"]),

    getMenuItemClasses({ page }) {
      const parentRoute = page ? this.$router.resolve({ name: page }).href : page;
      const routePath = this.$route.path;
      const path = `${getBasePath()}${routePath}`;

      return {
        "menu-item-active":
          (this.checkCurrentPage(page) || path.includes(parentRoute)) && this.activeItem,
      };
    },

    getUrl({ link }) {
      if (link) return link.url;
    },

    getTarget({ link }) {
      if (link) return link.targetBlank;
    },

    onClickItem({ page }) {
      if (this.isTabletDevice) {
        this.CLOSE_ASIDE_SUB_BLOCK();
      }

      if (this.checkCurrentPage(page) && this.isMobileDevice) this.CLOSE_MOBILE_SUB_BLOCK();
    },

    async getData() {
      const filters = {
        dateFrom: DateService.thirtyDaysAgo(),
        dateTo: DateService.nextTwoWeek(),
      };

      const { productCategories } = await this.$get(dashboardDataQuery, {
        page: this.page,
        perPage: this.perPage,
        mutualPerPage: this.perPage,
        settlementsFilters: filters,
      });

      if (productCategories) {
        this.categories = productCategories;
      }
    },

    clearCategory() {
      // if (this.$route.query.category) {
      //   // this.$router.push({ name: "Products", query: {} });
      //   this.SET_CATEGORY({});
      // }

      if (this.$route.name !== "Products") {
        this.$router.push({ name: "Products" });
      }

      this.showCategories = !this.showCategories;
    },

    onClickCategory(category) {
      if (+category.id === 643) {
        if (this.$router.currentRoute.name === "FleaMarket") return;

        this.$router.push({ name: "FleaMarket" });
      } else {
        this.SET_CATEGORY(category);

        if (this.$router.currentRoute.name !== "Products") {
          this.$router.push({ name: "Products" });
        }
      }

      // this.CLOSE_MOBILE_SUB_BLOCK();
    },
  },
};
</script>

<style lang="postcss" scoped>
.category-arrow {
  @apply flex;
  margin-left: auto !important;
}
.category-list:deep {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 80vh;
  background-color: #f3f4f6;

  .categories-list .group:hover {
    background-color: transparent;
  }

  .categories-list .group .category {
    padding: 10px 5px;
  }
}
.perfect-scrollbar {
  @apply h-full;

  .menu-wrap {
    @apply overflow-y-auto;

    .menu {
      &-item {
        &-section {
          @apply px-6 py-2 md:px-4 md:pt-0;
          @apply cursor-auto;

          &-text {
            @apply text-sm font-normal text-gray-400;
          }
        }

        &-link {
          @apply flex w-full cursor-pointer;

          &:deep(a) {
            @apply w-full;
          }

          &-block {
            @apply w-full;
            @apply flex items-center space-x-3;
            @apply px-6 md:px-4;

            &:hover {
              @apply bg-gray-200/50 md:rounded-lg;
              @apply text-gray-800;
            }
          }

          &-text {
            @apply flex items-center;
            @apply py-2.5;
            @apply text-base font-normal text-gray-600;
            @apply overflow-hidden text-ellipsis whitespace-nowrap;
          }

          &-line {
            @apply h-px w-auto;
            @apply mx-3 my-2;
            @apply bg-gray-700;
          }
        }
      }

      &-item-active {
        @apply bg-gray-200 md:rounded-lg;

        .menu-item-link-text {
          @apply text-gray-800;
        }
      }

      .menu-item + .menu-item {
        @apply mt-px;
      }
    }
  }
}
</style>
