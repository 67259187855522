<template>
  <OurPage
    class="access-groups-edit"
    :back-route="backRoute"
    :title="$t('title.bonuses.editLevel')"
    width="md"
  >
    <LevelsForm ref="levelsForm" v-model="form" />

    <template #footer-left>
      <OurButton class="button" :text="$t('button.save')" @click="onClickSaveButton" />
      <OurLink :route="backRoute">
        <OurButton class="button" :text="$t('button.back')" variant="secondary" />
      </OurLink>
    </template>
  </OurPage>
</template>

<script>
import apolloMixin from "@/mixins/apollo.mixin";
import { bonusProgramLevelQuery } from "@/views/settings/bonuses/levels/edit/gql/queries";
import LevelsForm from "@/views/settings/bonuses/_components/LevelsForm.vue";
import { updateBonusProgramLevelMutation } from "@/views/settings/bonuses/levels/edit/gql/mutations";

export default {
  name: "BonusLevelEdit",

  components: {
    LevelsForm,
  },

  mixins: [apolloMixin],

  data() {
    return {
      backRoute: {
        name: "BonusLevels",
        title: this.$t("title.bonuses.levels"),
      },
      form: {
        name: "",
        threshold: 0,
        benefits: [],
      },
    };
  },

  computed: {
    levelId() {
      return this.$route.params.id;
    },
  },

  created() {
    this.getData();
  },

  methods: {
    async getData() {
      const { bonusProgramLevel } = await this.$get(bonusProgramLevelQuery, {
        id: this.levelId,
      });

      if (bonusProgramLevel) {
        this.form = {
          ...bonusProgramLevel,
        };
      }
    },

    async onClickSaveButton() {
      this.$refs.levelsForm.emitForm();

      if (this.form.isValid) {
        this.form.threshold = parseInt(this.form.threshold);

        const { updateBonusProgramLevel } = await this.$post(
          updateBonusProgramLevelMutation,
          {
            ...this.form,
            benefits: this.form.benefits.map((benefit) => {
              const { id, enabled, value } = benefit;

              return {
                id,
                enabled,
                value,
              };
            }),
          },
          {
            delaySuccessNotify: true,
          }
        );

        if (updateBonusProgramLevel.id) await this.$router.push({ name: "BonusLevels" });
      }
    },
  },
};
</script>
