<template>
  <OurPage class="users-add" :title="$t('title.users.add')" :back-route="backRoute" width="md">
    <EmployeeForm ref="employeeForm" v-model="form" class="user-form" :roles="roles" />
    <template #footer-left>
      <OurButton class="button" :text="$t('button.add')" @click="onClickSaveButton" />
      <OurButton
        class="button"
        :text="$t('button.back')"
        variant="secondary"
        @click="$router.back()"
      />
    </template>
  </OurPage>
</template>

<script>
import EmployeeForm from "@/views/settings/users/_components/EmployeeForm.vue";
import apolloMixin from "@/mixins/apollo.mixin";
import { createEmployeeMutation } from "@/views/settings/users/add/gql/mutations";
import { rolesQuery } from "@/views/settings/users/add/gql/queries";

export default {
  name: "UsersAdd",
  components: { EmployeeForm },
  mixins: [apolloMixin],
  data() {
    return {
      roles: [],
      backRoute: {
        name: "Back",
        title: this.$t("title.users._"),
      },
      form: {
        firstName: "",
        lastName: "",
        email: "",
        roleId: "",
        password: "",
        isActive: false,
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const { roles } = await this.$get(rolesQuery);

      this.roles = roles
        .filter((role) => role.parent?.code === "employee" && role.code !== "superAdmin")
        .map((role) => {
          const { id, name } = role;

          return {
            id,
            label: name,
          };
        });
    },
    async onClickSaveButton() {
      this.$refs.employeeForm.emitForm();

      if (this.form.isValid) {
        const { createEmployee } = await this.$post(createEmployeeMutation, this.form, {
          delaySuccessNotify: true,
        });

        if (createEmployee.id) this.$router.back();
      }
    },
  },
};
</script>

<style scoped lang="postcss">
.users-add {
  @apply relative;

  .user-form {
    @apply mb-6;
  }
}
</style>
