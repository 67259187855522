<template>
  <div class="form">
    <OurGroup class="form-group">
      <OurInput
        v-model="form.companyName"
        :label="$t('label.companyTitle')"
        :placeholder="$t('placeholder.title')"
      />

      <OurInput
        v-model="form.id"
        :label="$t('label.id')"
        :placeholder="$t('placeholder.caseNumber')"
      />

      <OurSelect
        v-model="form.status"
        :label="$t('label.status')"
        :placeholder="$t('placeholder.selectStatus')"
        :options="getStatuses()"
        item-label="text"
        value-key="value"
      />
    </OurGroup>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "FilterForm",

  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      form: {
        companyName: "",
        id: "",
        status: "",
      },
    };
  },

  computed: {
    ...mapGetters("newApplications", ["getStatuses"]),
  },

  watch: {
    value: {
      handler: "setForm",
      deep: true,
    },

    form: {
      handler: "onChangeForm",
      deep: true,
    },
  },

  mounted() {
    this.setForm();
  },

  methods: {
    setForm() {
      this.form = this.value;
    },

    onChangeForm() {
      this.$emit("input", this.form);
    },
  },
};
</script>
