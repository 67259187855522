<template>
  <OurPage class="users-add" :title="$t('title.managers.edit')" :back-route="backRoute" width="md">
    <DeleteModal
      :show.sync="isShownDeleteModal"
      :user-id="userId"
      :name="form.name"
      :mutation="deleteManagerMutation()"
      @deleteUser="goToList"
    />

    <UsersForm
      ref="userForm"
      v-model="form"
      class="user-form"
      :upload-files="uploadFiles"
      @uploadFiles="uploadFilesMutation"
      @deleteFiles="deleteFileMutation"
    />

    <template #footer-left>
      <OurButton
        v-if="getPermission['manager.update']"
        class="button"
        :text="$t('button.save')"
        @click="onClickSaveButton"
      />
      <OurButton
        class="button"
        :text="$t('button.back')"
        dashed
        variant="secondary"
        @click="$router.back()"
      />
    </template>

    <template #footer-right>
      <OurButton
        v-if="getPermission['manager.delete']"
        class="button"
        variant="thirdary"
        :text="$t('button.remove')"
        @click="onClickDeleteButton"
      />
    </template>
  </OurPage>
</template>

<script>
import { mapGetters } from "vuex";
import apolloMixin from "@/mixins/apollo.mixin";
import { managerQuery } from "./gql/queries";
import { updateManagerMutation } from "./gql/mutations";
import UsersForm from "../_components/UserForm";
import DeleteModal from "@/components/modals/DeleteModal.vue";
import { deleteManagerMutation } from "@/views/settings/managers/delete/gql/mutations";
import {
  deleteFileMutation,
  uploadFilesMutation,
} from "@/views/settings/managers/edit/gql/mutations";

export default {
  name: "ManagersEdit",

  components: {
    UsersForm,
    DeleteModal,
  },

  mixins: [apolloMixin],

  data() {
    return {
      isShownDeleteModal: false,
      uploadFiles: null,
      backRoute: {
        name: "Back",
        title: this.$t("title.managers._"),
      },
      form: {
        id: "",
        name: "",
        email: "",
        phone: "",
        isActive: false,
        image: null,
      },
    };
  },

  computed: {
    ...mapGetters("user", ["userProfile", "isUserDealer", "getPermission"]),

    userId() {
      return this.$route.params.id;
    },
  },

  created() {
    this.getData();
  },

  methods: {
    async getData() {
      const userId = this.userId;
      const { manager } = await this.$get(managerQuery, { id: userId });

      if (manager) {
        const { isActive, name, email, phone, image } = manager;

        this.form = {
          id: userId,
          name,
          email,
          phone: phone || "",
          image,
          isActive,
        };
      }
    },

    deleteManagerMutation() {
      return deleteManagerMutation;
    },

    async onClickSaveButton() {
      this.$refs.userForm.emitForm();

      if (this.form.isValid) {
        this.form.files = { sync: this.form.image[0] || [] };
        const form = {
          ...this.form,
        };
        const { updateManager } = await this.$post(updateManagerMutation, form, {
          delaySuccessNotify: true,
        });

        if (updateManager.id) this.goToList();
      }
    },

    async uploadFilesMutation(selectedFiles) {
      const { uploadFiles: files } = await this.$post(
        uploadFilesMutation,
        { files: selectedFiles },
        { withLoader: false, withNotify: false }
      );

      this.uploadFiles = files;
    },

    async deleteFileMutation(id) {
      await this.$post(deleteFileMutation, { id }, { withLoader: false, withNotify: false });
    },

    onClickDeleteButton() {
      this.isShownDeleteModal = true;
    },

    goToList() {
      this.$router.push({ name: "Managers" });
    },
  },
};
</script>
