import gql from "graphql-tag";

export const updateCustomerMutation = gql`
  mutation updateCustomer(
    $id: ID!
    $name: String!
    $isActive: Boolean!
    $storageId: ID
    $accessGroupId: ID
    $defaultCompanyId: ID
    $companies: [ID]
    $levelId: ID
  ) {
    updateCustomer(
      id: $id
      name: $name
      isActive: $isActive
      storageId: $storageId
      accessGroupId: $accessGroupId
      defaultCompanyId: $defaultCompanyId
      companies: $companies
      levelId: $levelId
    ) {
      id
    }
  }
`;
