import i18n from "@/plugins/vue-i18n";

import Managers from "@/views/settings/managers/index";
import ManagersEdit from "@/views/settings/managers/edit/index.vue";

const managers = [
  {
    path: "settings/managers",
    name: "Managers",
    component: Managers,
    meta: {
      title: i18n.t("title.managers._"),
    },
  },
  {
    path: "settings/managers/:id/edit",
    name: "ManagersEdit",
    component: ManagersEdit,
    meta: {
      title: i18n.t("title.managers.edit"),
    },
  },
];

export default managers;
