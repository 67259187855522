import gql from "graphql-tag";

export const dealerCompanyQuery = gql`
  query company($companyId: ID!) {
    company(id: $companyId) {
      id
      oneCId
      name
      isActive
      taxNumber
      manager {
        name
      }
    }
  }
`;
