<template>
  <div class="categories">
    <div ref="categoriesList" class="categories-list">
      <div v-for="(categories, groupIndex) in mainCategories" :key="groupIndex" class="group">
        <div v-for="(category, index) in categories" :key="index">
          <div class="category flex" @click.stop="getChildren(category.children, index)">
            <div class="flex items-center justify-between">
              <router-link
                :to="mainCategoryRoute(category)"
                class="label"
                @click.prevent="onClickCategory(category.id)"
              >
                {{ category.description }}
              </router-link>
              <OurSvgIcon
                v-if="category.children.length"
                :src="icons.AngleRight"
                size="sm"
                color="gray"
                class="relative z-10"
                :class="{ 'rotate-90': subcategoryShowed === index }"
              />
            </div>
          </div>
          <div v-if="subcategoryShowed === index" class="flex flex-col">
            <div v-for="el of subCats" :id="el.id" :key="el.id" class="sub-category flex flex-col">
              <div class="category-child flex items-center justify-between">
                <router-link
                  :to="{ name: $route.name, query: { category: el.id } }"
                  class="relative z-10 block w-100"
                  @click.prevent.stop="onClickCategory(el.id)"
                >
                  {{ el.description }}
                </router-link>
                <OurSvgIcon
                  v-if="el.children.length"
                  :src="icons.AngleRight"
                  size="sm"
                  color="gray"
                  class="relative z-10"
                  :class="{ 'rotate-90': activeSubcategory === el.id }"
                  @click="getSubcategoryChildren(el)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CategoriesList",

  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      activeSubcategory: null,
      subcategoryShowed: null,
      subCats: [],
      mainCategories: [],
      parentHeight: null,
      parentWidth: null,
      baseFontSize: 16,
      subGroupIndex: 1,
      activeArrow: null,
      list: null,
    };
  },

  computed: {
    icons: () => ({
      AngleRight: require("@/components/_bit/IconSvg/icons/Angle-right.svg"),
    }),
  },

  watch: {
    "$route.query.category": {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) this.onClickCategory(newVal);
      },
      deep: true,
    },
  },

  created() {
    this.setMainCategories();

    if (this.$route.query.category) {
      this.onClickCategory(this.$route.query.category);
    }
  },

  mounted() {
    this.list = document.getElementById("list");
  },

  methods: {
    mainCategoryRoute(category) {
      if (category.id === "643") {
        return "/flea-market";
      } else if (this.$route.name !== "Products" && this.$route.name !== "Dashboard") {
        return { name: this.$route.name, query: { category: category.id } };
      } else {
        return `/products?category=${category.id}`;
      }
    },

    getChildren(arr, index) {
      this.activeSubcategory = null;

      if (this.subcategoryShowed !== index) {
        this.subCats = arr.map((id) => {
          return this.items.find((item) => item.id === id);
        });
        this.subcategoryShowed = index;
      } else {
        this.subcategoryShowed = null;
      }
    },

    getSubcategoryChildren(el) {
      const category = document.getElementById(el.id),
        list = category.querySelector("ul");

      if (category.contains(list)) {
        list.remove();

        return;
      }

      if (el.children.length) {
        let cat = el.children.map((id) => {
          return this.items.find((item) => item.id === id);
        });

        let sublist = document.getElementById(el.id);
        let list = document.createElement("ul");

        list.setAttribute("class", "subcategory");

        sublist.appendChild(list);

        cat.forEach((e) => {
          if (e.parent === el.id) {
            const li = document.createElement("li");
            const span = document.createElement("span");

            span.textContent = e.description;
            span.setAttribute("id", e.id);
            span.addEventListener("click", (event) => {
              if (event.target.hasAttribute("id")) {
                event.stopPropagation();
                this.onClickSubCategory(event.target.getAttribute("id"));
              }
            });
            li.appendChild(span);
            list.appendChild(li);

            if (e.children.length) {
              const img = document.createElement("img");

              img.src = this.icons.AngleRight;
              span.appendChild(img);

              img.addEventListener("click", (event) => {
                img.classList.toggle("active");
                event.stopPropagation();
                this.getSubcategoryChildren(e);
              });
            }
          }
        });
      }
    },

    setMainCategories() {
      const mainCategories = this.items.filter(({ parent }) => parent === null);

      this.mainCategories = [mainCategories];
    },

    onClickCategory(id) {
      if (id) {
        const selected = this.items.find((item) => item.id === id);

        this.$emit("clickCategory", selected);
      }
    },

    onClickSubCategory(id) {
      if (this.$route.name !== "Dashboard" && this.$route.name !== "Products") {
        this.$router.push({ name: this.$route.name, query: { category: id } });
      } else {
        if (this.$route.query.category !== id) {
          this.$router.push({ name: "Products", query: { category: id } });
        }
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
.categories-list {
  @apply flex;
  @apply h-fit max-h-full;
  @apply relative;
  .group {
    @apply divide-gray-100 hover:bg-white;
    @apply h-full w-full;
    & div {
      @apply w-full cursor-pointer;
    }
    .category {
      @apply relative flex items-center justify-between;
      @apply cursor-pointer;
      @apply rounded-lg;
      @apply w-full space-x-5 py-3;
      &:hover {
        &:after {
          content: "";
          position: absolute;
          left: -2%;
          top: 0;
          height: 100%;
          width: 104%;
          display: block;
          z-index: 0;
          @apply rounded-lg bg-gray-900 bg-opacity-5;
        }
      }
      &-child {
        @apply relative p-2 text-sm;
        &:hover {
          &:after {
            content: "";
            position: absolute;
            left: -2%;
            top: 0;
            height: 100%;
            width: 104%;
            display: block;
            z-index: 0;
            @apply rounded-lg bg-gray-900 bg-opacity-5;
          }
        }
      }
      .label {
        @apply relative z-10 text-sm font-medium;
      }
    }
  }
}

.categories-list .group .category {
  align-items: flex-start !important;
  & > :not([hidden]) ~ :not([hidden]) {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
</style>

<style>
.subcategory {
  @apply px-3 py-2 text-xs;
  li {
    @apply py-2;
    span {
      display: grid;
      grid-template-columns: 5fr 1fr;
      img {
        display: inline-block;
        margin-left: 20px;
        filter: contrast(0.1);
        width: 20px;
        vertical-align: middle;
        &.active {
          transform: rotate(90deg);
        }
      }
    }
  }
}
</style>
