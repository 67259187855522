import gql from "graphql-tag";

export const customerAndBonusLevelQuery = gql`
  query customer($customerId: ID!) {
    customer(id: $customerId) {
      id
      name
      isActive
      storage {
        id
      }
      accessGroup {
        id
      }
      companies {
        id
        name
      }
      defaultCompany {
        id
      }
      dealers {
        id
        firstName
        lastName
        detail {
          ... on Dealer {
            status
          }
        }
        phone
        email
        roles {
          name
        }
      }
      bonusProgramLevel {
        id
      }
    }
    bonusProgramLevels {
      id
      name
    }
  }
`;
