<template>
  <div>
    <OurDivider v-if="upperlined" size="xl" no-top-padding />

    <OurEmptyBlock
      v-if="!hideEmptyStateForNesting && !list?.length"
      :description="emptyDescription"
      :title="emptyTitle"
    />

    <draggable
      v-else
      v-bind="dragOptions"
      :list="list"
      :group="{ name: group }"
      class="list"
      handle=".drag-icon"
      drag-class="draggable-item"
      :class="themeClass"
      :data-cy="dataCy"
      :move="onDragMove"
      @end="onDragEnd"
    >
      <div
        v-for="(row, index) in list"
        :id="row.id"
        :key="row.id"
        class="list-items"
        :data-cy="`${dataCy}-item`"
      >
        <div class="list-item-parent" :data-cy="`${dataCy}-item-${index}`">
          <div v-if="isDraggable" class="list-item-left">
            <OurSvgIcon
              class="icon-dial-numbers drag-icon"
              :src="icons.DialNumbers"
              color="gray"
              variant="light"
            />
          </div>

          <slot :item="row">
            <div
              class="list-item-left-title cursor-pointer"
              :class="{ left: !isDraggable }"
              @click="openChapter(row.id)"
            >
              {{ row.name }}
            </div>
          </slot>

          <div v-if="!row.isHiddenAction" class="list-item-right">
            <div v-if="isShownIconsSlot" class="icon-wrapper">
              <!-- @slot Use it to add icons. -->
              <slot name="icons" :item="row" />
            </div>

            <OurSvgIcon
              v-if="havePermissionDelete"
              interactive
              class="icon-trash icon"
              :src="icons.Trash"
              color="red"
              :data-cy="`${dataCy}-delete`"
              :tooltip="$t('button.delete')"
              @click="onClickDelete(row.id, row.name)"
            />

            <OurSvgIcon
              v-if="havePermissionEdit"
              interactive
              class="icon-edit"
              :src="icons.Edit"
              color="gray"
              :data-cy="`${dataCy}-edit`"
              :tooltip="$t('button.edit')"
              @click="onClickEdit(row.id)"
            />
          </div>
        </div>

        <OurTableList
          v-if="nesting && !row.isDisabledNesting"
          :nesting="nesting"
          hide-empty-state-for-nesting
          class="list-nested"
          :list="row.children"
          :group="group"
          :data-cy="`${dataCy}-table`"
          @clickDelete="onClickDelete"
          @clickEdit="onClickEdit"
          @dragSort="onDragEnd"
        >
          <template #default="{ item }">
            <slot :item="item">
              <div class="list-item-left-title">{{ item.name }}</div>
            </slot>
          </template>

          <template #icons="{ item }">
            <!-- @slot Use it to add icons. -->
            <slot name="icons" :item="item" />
          </template>
        </OurTableList>
      </div>
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { getThemeClass } from "@/services/_bit/UiService";

import OurSvgIcon from "@/components/_bit/IconSvg";
import OurDivider from "@/components/_bit/Divider";

export default {
  name: "OurTableList",

  components: {
    OurSvgIcon,
    OurDivider,
    draggable,
  },

  props: {
    /**
     * Set data list for component.
     */
    list: {
      type: Array,
      default: () => [],
    },

    /**
     * Set group name.
     */
    group: {
      type: String,
      default: "",
    },

    /**
     * Enable or disable nesting.
     */
    nesting: {
      type: Boolean,
      default: false,
    },

    /**
     * Sets empty state title when no data in a table.
     */
    emptyTitle: {
      type: String,
      default: "",
    },

    /**
     * Sets empty state description when no data in a table.
     */
    emptyDescription: {
      type: String,
      default: "",
    },

    /**
     * Add line divider above the list.
     */
    upperlined: {
      type: Boolean,
      default: false,
    },

    /**
     * Sets data-cy attribute for correct element sampling in tests.
     */
    dataCy: {
      type: String,
      default: "",
    },

    /**
     * @ignore
     * Disable empty state for nested elements if empty (internal prorps).
     */
    hideEmptyStateForNesting: {
      type: Boolean,
      default: false,
    },

    havePermissionDelete: {
      type: Boolean,
      default: false,
    },

    havePermissionEdit: {
      type: Boolean,
      default: false,
    },

    isDraggable: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: this.group,
        disabled: false,
        ghostClass: "ghost",
      };
    },

    icons: () => ({
      Trash: require("@/components/_bit/IconSvg/icons/Trash.svg"),
      DialNumbers: require("@/components/_bit/IconSvg/icons/Dial-numbers.svg"),
      Edit: require("@/components/_bit/IconSvg/icons/Edit.svg"),
    }),

    themeClass() {
      return getThemeClass();
    },

    isShownIconsSlot() {
      return !!this.$scopedSlots["icons"];
    },
  },

  methods: {
    onDragMove(event) {
      const isDisabledNestingItem = event.draggedContext.element.isDisabledNesting;
      const isNestingAction = !event.relatedContext.element?.isDisabledNesting;

      if (isDisabledNestingItem && isNestingAction) {
        return false;
      }
    },

    onDragEnd() {
      const sortData = this.prepareSortData(this.list);

      this.$emit("dragSort", sortData);
    },

    onClickEdit(id) {
      this.$emit("clickEdit", id);
    },

    onClickDelete(id, title) {
      this.$emit("clickDelete", id, title);
    },

    openChapter(id) {
      this.$emit("clickChapter", id);
    },

    prepareSortData(list, parentId) {
      let sortData = [];

      list.forEach((item) => {
        let hasItemChildren = item?.children?.length;

        if (hasItemChildren) {
          let childrenItem = this.prepareSortData(item.children, item.id);

          childrenItem.forEach((item) => {
            sortData.push(item);
          });
        }

        let parentItem = { id: item.id, parentId: 0 || parentId };

        sortData.push(parentItem);
      });

      return sortData;
    },
  },
};
</script>

<style lang="postcss" scoped>
.ghost {
  @apply bg-blue-100 bg-opacity-50;
}

.draggable-item {
  @apply rounded-lg bg-gray-200;
  @apply border-b-0;
}

.sortable-chosen {
  @apply rounded-lg;

  .list-item-parent {
    @apply border-b-0;
  }
}

.list {
  &-nested {
    @apply ml-6;
  }

  &-item {
    &-parent {
      @apply flex items-center justify-between;
      @apply py-4;
      @apply border border-l-0 border-r-0 border-t-0 border-solid border-gray-100;

      &:hover {
        &:deep() {
          .icon {
            @apply opacity-100;
            @apply transition duration-100 ease-in-out;

            &-edit {
              & g [fill] {
                @apply text-gray-500;
              }
            }
          }
        }

        .icon-wrapper {
          @apply opacity-100;
          @apply transition duration-100 ease-in-out;
        }
      }
    }

    &-left {
      @apply flex items-center;

      &-title {
        @apply flex-auto;
        @apply text-base font-normal text-gray-900;
        @apply ml-5 pt-px;
        word-break: break-word;
        &.left {
          margin-left: 0;
        }
      }
    }

    &-right {
      @apply flex flex-none items-center justify-end;
      @apply w-28 space-x-5;
    }
  }

  &-items {
    &:last-child {
      .list-item-parent {
        @apply border-b-0;
      }
    }
  }

  &-nested {
    .list-items {
      &:last-child {
        .list-item-parent {
          @apply border-b;
        }
      }
    }
  }
}

.icon {
  @apply opacity-0;

  &-wrapper {
    @apply flex items-center space-x-5;
    @apply opacity-0;
  }

  &-edit {
    &:deep() {
      & g [fill] {
        @apply text-gray-300;
      }
    }
  }

  &-dial-numbers {
    @apply cursor-move;

    &:deep() {
      & g [fill] {
        @apply text-gray-400;
        @apply opacity-100;
      }

      &:hover {
        g [fill] {
          @apply text-gray-500;
        }
      }
    }
  }
}
</style>
