<template>
  <div class="user-form">
    <OurGroups>
      <OurGroup>
        <OurInput
          v-model="form.name"
          :label="$t('label.lastName')"
          :placeholder="$t('placeholder.lastName')"
          :error="nameError"
        />

        <OurInput
          v-model="form.email"
          :label="$t('label.email')"
          :placeholder="$t('placeholder.email')"
          :error="emailError"
        />

        <OurPhoneInput
          v-model="form.phone"
          :label="$t('label.phoneNumber')"
          :placeholder="$t('placeholder.phone')"
          :flags="false"
          :error="phoneError"
          dropdown-disabled
        />

        <OurFileInput
          :label="$t('label.selectPhoto')"
          :files="files"
          preview
          button-size="xl"
          :disabled="!!files.length"
          :allowed-file-types="allowedFileTypes"
          @changeFiles="onChangeFiles"
          @deleteFile="deleteFile"
        />

        <div class="footer-block">
          <OurCheckbox v-model="form.isActive" :label="$t('page.managers.isActive')" />
        </div>
      </OurGroup>
    </OurGroups>
  </div>
</template>

<script>
import ValidationService from "@/services/validation.service";
import { email, required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

const { phoneMaxLength, phoneMinLength } = ValidationService;

export default {
  name: "UsersForm",

  props: {
    value: {
      type: Object,
      default: () => {},
    },

    uploadFiles: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      files: [],
      allowedFileTypes: [".jpg", ".jpeg", ".png"],
      form: {
        name: "",
        email: "",
        phone: "",
        isActive: false,
        image: null,
      },
    };
  },

  validations: {
    form: {
      name: { required },
      email: { required, email },
      phone: { required, phoneMinLength, phoneMaxLength },
    },
  },

  computed: {
    ...mapGetters("user", ["isUserDealerAdmin", "isUserDealer"]),

    nameError() {
      const isDirty = this.$v.form.name.$dirty;
      const isFilled = this.$v.form.name.required;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      }

      return error;
    },

    emailError() {
      const isDirty = this.$v.form.email.$dirty;
      const isFilled = this.$v.form.email.required;
      const isEmail = this.$v.form.email.email;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      } else if (isDirty && !isEmail) {
        error = this.$t("validation.mustBeEmail");
      }

      return error;
    },

    phoneError() {
      const isDirty = this.$v.form.phone.$dirty;
      const isFilled = this.$v.form.phone.required;

      let isMinLength;
      let isMaxLength;

      if (this.form.phone) {
        isMinLength = this.$v.form.phone.phoneMinLength;
        isMaxLength = this.$v.form.phone.phoneMaxLength;
      }

      let error = "";

      if (isDirty) {
        if (!isFilled) error = this.$t("validation.required");
        else if (!isMinLength || !isMaxLength) error = this.$t("validation.phoneLength");
      }

      return error;
    },
  },

  watch: {
    value: {
      handler: "getForm",
      deep: true,
    },
    uploadFiles: {
      handler: "getUploadFiles",
      deep: true,
    },
    files: {
      handler: "getFormFiles",
      deep: true,
    },
  },

  mounted() {
    this.getForm();
  },

  methods: {
    getForm() {
      if (this.value) {
        this.form = { ...this.value };

        const { image } = this.value;

        if (image && image.link) {
          this.files = [{ id: image?.id, name: image?.name, imageUrl: image.link }];
        }
      }
    },

    async deleteFile(id) {
      await this.$emit("deleteFiles", id);

      this.files.forEach((file) => {
        if (file.id !== id) return file;
      });
    },

    async onChangeFiles(selectedFiles) {
      if (selectedFiles.length) {
        await this.$emit("uploadFiles", selectedFiles);
      }
    },

    getUploadFiles() {
      this.uploadFiles.forEach((file) => {
        const { id, name, link: imageUrl } = file;

        this.files.push({ id, name, imageUrl });
      });
    },

    getFormFiles() {
      this.form.image = this.files.map((file) => file.id);
    },

    emitForm() {
      if (this.$v.$invalid) {
        this.$v.$touch();

        return;
      }

      this.form.isValid = true;
      this.$emit("input", this.form);
    },
  },
};
</script>
