<template>
  <div class="users-list">
    <template v-if="isMobileDevice">
      <div v-if="items.length">
        <CustomersCard
          v-for="item of items"
          :id="item.id"
          :key="item.id"
          :manager="item.user"
          :image="item.image.image"
          :status="item.status"
          :contacts="item.contacts"
          @clickOnOrder="onClickRow({ id: item.id })"
        />
      </div>
      <div v-else>{{ $t("page.dashboard.emptyMessage") }}</div>
    </template>

    <OurTable
      v-else
      :headers="headers.length ? headers : tableHeaders"
      :items="items"
      :table-fixed="false"
      class="users-list-table"
      @clickRow="onClickRow"
    >
      <template #cell-image="{ value }">
        <OurAvatar :path="value?.image" :user-name="value?.name" size="2xl" rounded="full" />
      </template>

      <template #cell-contacts="{ value }">
        <div class="contacts-block">
          <div>{{ value.phone }}</div>
          <OurLink
            class="email"
            :text="value.email"
            :url="value.email"
            type="email"
            size="xs"
            color="gray"
            dashed
            no-focus-ring
            @click.native.stop
          />
          <span v-if="!value.phone && !value.email">–</span>
        </div>
      </template>

      <template #cell-status="{ value }">
        <OurTag size="md" :text="value?.text" :color="value?.color" />
      </template>
    </OurTable>

    <OurPagination
      v-if="isMoreThanOnePage"
      :current-page="paginationData.currentPage"
      :per-page="paginationData.perPage"
      :total="paginationData.total"
      @pageChange="onPageChange"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MainSubMenu from "@/layouts/_bit/admin-mono/MainSubMenu.vue";
import CustomersCard from "@/components/MobileCards/CustomersCard.vue";

export default {
  name: "ManagersList",
  components: { CustomersCard, MainSubMenu },

  props: {
    items: {
      type: Array,
      default: () => [],
    },

    headers: {
      type: Array,
      default: () => [],
    },

    paginationData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      isShownMenuBlock: null,
    };
  },

  computed: {
    ...mapGetters("breakpoint", ["isMobileDevice"]),
    ...mapGetters("user", ["isUserDealer", "getPermission", "userProfileName"]),
    icons: () => ({
      more: require("@material-symbols/svg-400/outlined/more_horiz-fill.svg"),
    }),
    tableHeaders() {
      const data = [
        {
          value: "image",
          thClass: "w-[5rem]",
          tdClass: "max-w-[5rem] min-w-[5rem]",
        },
        {
          value: "id",
          text: this.$t("page.managers.id"),
          thClass: "w-[3rem]",
          tdClass: "max-w-[2rem] min-w-[3rem]",
        },
        {
          value: "user",
          text: this.$t("page.managers.user"),
          thClass: "w-[5rem]",
          tdClass: "max-w-[10rem] min-w-[10rem]",
        },
        {
          value: "contacts",
          text: this.$t("page.managers.contacts"),
          thClass: "w-[7rem]",
          tdClass: "max-w-[10rem] min-w-[7rem]",
        },
        {
          value: "status",
          text: this.$t("page.managers.status"),
          thClass: "w-[7rem]",
          tdClass: "max-w-[9rem] min-w-[7rem]",
        },
      ];

      return data;
    },

    isMoreThanOnePage() {
      return this.paginationData.lastPage > 1;
    },
  },

  methods: {
    ...mapActions(["loginImpersonate"]),

    onPageChange(page) {
      this.$emit("pageChange", page);
    },

    onClickRow(row) {
      this.$emit("onClickRow", row.id);
    },
  },
};
</script>
