import gql from "graphql-tag";

export const updateManagerMutation = gql`
  mutation updateManager(
    $id: ID!
    $name: String!
    $email: String!
    $phone: String!
    $isActive: Boolean
    $files: SyncAssociations
  ) {
    updateManager(
      id: $id
      name: $name
      email: $email
      phone: $phone
      isActive: $isActive
      files: $files
    ) {
      id
    }
  }
`;

export const uploadFilesMutation = gql`
  mutation uploadFiles($files: [Upload!]!) {
    uploadFiles(files: $files) {
      id
      name
      link
    }
  }
`;

export const deleteFileMutation = gql`
  mutation deleteFile($id: ID!) {
    deleteFile(id: $id) {
      id
      name
    }
  }
`;
