import i18n from "@/plugins/vue-i18n";

const Orders = () => import(/* webpackChunkName: "orders" */ "@/views/orders/index");

const OrderList = () => import("@/views/orders/order/index.vue");
const DraftList = () => import("@/views/orders/draft/index.vue");

const OrdersDetails = () => import(/* webpackChunkName: "orders" */ "@/views/orders/order/details");

const OrdersDraftDetails = () =>
  import(/* webpackChunkName: "orders" */ "@/views/orders/draft/details");

const order = [
  {
    path: "/orders",
    component: Orders,
    children: [
      {
        path: "/orders",
        name: "Orders",
        component: OrderList,
        meta: {
          title: i18n.t("title.orders._"),
        },
      },
      {
        path: "drafts",
        name: "Drafts",
        component: DraftList,
        meta: {
          title: i18n.t("title.orders.drafts"),
        },
      },
    ],
  },
  {
    path: "/orders/:id",
    name: "OrdersDetails",
    component: OrdersDetails,
    meta: {
      title: i18n.t("title.orders._"),
    },
  },
  {
    path: "/orders/draft/:id",
    name: "OrdersDraftDetails",
    component: OrdersDraftDetails,
    meta: {
      title: i18n.t("title.orders.drafts"),
    },
  },
];

export default order;
