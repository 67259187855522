export default {
  data() {
    return {
      queries: {},
    };
  },
  created() {
    this.getQueryParam();
  },
  methods: {
    cleanQueryParam() {
      this.queries = {};
    },
    getQueryParam() {
      for (const key in this.$route.query) {
        if (key === "purchase-order") return;

        if (key === "date" || key === "amount") {
          this.form[key] = {
            from: this.$route.query[key][0],
            to: this.$route.query[key][1],
          };
        } else if (key === "isActive") {
          this.form[key] = this.$route.query[key] === "true";
        } else {
          this.form[key] = this.$route.query[key];
        }
      }
    },
    setQueryParam() {
      Object.keys(this.form).filter((item) => {
        if (this.form[item] !== "" && item !== "date" && item !== "amount") {
          this.queries[item] = this.form[item];
        }

        if (item === "date") {
          this.queries[item] = [this.form[item].from, this.form[item].to];
        }

        if (item === "amount") {
          if (this.form[item]?.from && this.form[item]?.to) {
            this.queries[item] = [this.form[item].from, this.form[item].to];
          } else if (this.form[item]?.from) {
            this.queries[item] = [this.form[item].from];
          } else if (this.form[item]?.to) {
            this.queries[item] = [0, this.form[item].to];
          }
        }
      });

      this.$router.replace({ name: this.$route.name, query: this.queries }).catch(() => {});
    },
  },
};
