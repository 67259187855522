<script>
import { mapGetters } from "vuex";
import apolloMixin from "@/mixins/apollo.mixin";
import { bonusProgramTextsQuery } from "./gql/queries";
import { reorderBonusProgramTextsMutation } from "./gql/mutations";

export default {
  mixins: [apolloMixin],

  data() {
    return {
      bonusTextsSections: [],
      backRoute: {
        name: "Bonuses",
        title: this.$t("title.bonuses._"),
      },
    };
  },

  computed: {
    ...mapGetters("user", ["getPermission"]),
  },

  created() {
    this.getData();
  },

  methods: {
    async getData() {
      const { bonusProgramTexts } = await this.$get(bonusProgramTextsQuery);

      if (bonusProgramTexts) {
        this.bonusTextsSections = bonusProgramTexts.map((bonusTextsSection) => {
          const { id, title } = bonusTextsSection;

          return {
            id,
            name: title,
          };
        });
      }
    },

    onClickEdit(id) {
      this.$router.push({ name: "BonusProgramEdit", params: { id } });
    },

    async dragHandler(data) {
      const order = data.map((object) => {
        return object.id;
      });

      await this.$post(
        reorderBonusProgramTextsMutation,
        { order: order },
        {
          delaySuccessNotify: false,
        }
      );
    },
  },
};
</script>

<template>
  <OurPage :back-route="backRoute" class="access-groups" :title="$t('title.bonuses._')" width="md">
    <OurTableList
      :have-permission-edit="getPermission['bonus_program.bonus_program']"
      :list="bonusTextsSections"
      @clickEdit="onClickEdit"
      @dragSort="dragHandler"
    />
  </OurPage>
</template>
