import gql from "graphql-tag";

export const customersDataQuery = gql`
  query customersDataQuery {
    accessGroups {
      id
      name
    }
    storages {
      id
      name
    }
    bonusProgramLevels {
      id
      name
    }
  }
`;
