<template>
  <OurModal v-model="isShownModal" :title="$t('title.fleaMarket.filters')" mobile-footer-reverse>
    <FilterForm ref="filterForm" v-model="form" />

    <template #footer-left>
      <OurButton :text="$t('button.apply')" @click="onClickApplyButton" />

      <OurButton :text="$t('button.close')" variant="secondary" @click="onClickCloseButton" />
    </template>

    <template #footer-right>
      <OurButton
        class="button"
        :text="$t('button.clear')"
        variant="thirdary"
        @click="onClickCleanButton"
      />
    </template>
  </OurModal>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import FilterForm from "./components/FilterForm";
import filterMixin from "@/mixins/filter.mixin";

export default {
  name: "FilterModal",
  components: {
    FilterForm,
  },
  mixins: [filterMixin],

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {
        "companies.id": "",
        "companies.name": "",
        managerId: "",
        customerId: "",
        status: "",
        taxNumber: "",
      },
    };
  },

  computed: {
    ...mapState("dealerCompany", ["filtersForm", "companies"]),

    isShownModal: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },

  watch: {
    isShownModal: "onChangeShownModal",
  },

  created() {
    this.setFiltersForm();
  },

  methods: {
    ...mapMutations("dealerCompany", ["SET_FILTERS_FORM"]),

    onChangeShownModal() {
      if (this.isShownModal) {
        if (this.filtersForm) {
          this.getForm();
        }
      } else {
        this.cleanForm();
      }
    },

    getForm() {
      this.form = { ...this.filtersForm };
    },

    cleanForm() {
      this.form = {
        "companies.id": "",
        "companies.name": "",
        managerId: "",
        accessGroupId: "",
        status: "",
        taxNumber: "",
      };
      this.cleanQueryParam();
    },

    closeModal() {
      this.isShownModal = false;
      this.cleanForm();
    },

    onClickCloseButton() {
      this.closeModal();
    },

    onClickApplyButton() {
      this.$refs.filterForm.onChangeForm();

      if (this.$refs.filterForm.isValid) {
        this.setFiltersForm();
        this.setQueryParam();
        this.closeModal();
      }
    },

    onClickCleanButton() {
      this.cleanForm();
    },

    setFiltersForm() {
      this.companies.find((company) => {
        if (company.id === this.form["companies.name"]) {
          this.form["companies.name"] = company.label;
        }
      });

      this.SET_FILTERS_FORM(this.form);
    },
  },
};
</script>
