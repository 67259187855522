import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtServiceDefault from "@/services/_bit/JwtService";
import NotifyServiceDefault from "@/services/_bit/NotifyService";
import I18nServiceDefault from "@/services/_bit/I18nService";

export default class ApiServiceDefault {
  #notify = new NotifyServiceDefault();

  /**
   * Set axios interceptors
   * @param { Object } $axios
   */
  setAxiosInterceptors($axios) {
    /**
     * Save url to general queue for cancel it, if duplicates
     */
    const addRequestUrl = (url) => {
      // eslint-disable-next-line no-console
      console.log("addRequestUrl", url);
      // store.commit("loaderTop/ADD_REQUEST_URL", url);
    };

    /**
     * Remove url from general queue
     */
    const removeRequestUrl = (url) => {
      // eslint-disable-next-line no-console
      console.log("removeRequestUrl", url);
      //store.commit("loaderTop/REMOVE_REQUEST_URL", url);
    };

    $axios.interceptors.request.use((config) => {
      const controller = new AbortController();

      addRequestUrl(config.url);

      const isPresentDuplicatedRequestInQueue = [].filter((item) => item === config.url).length > 1;
      // store.state.loaderTop.requestQueue.filter((item) => item === config.url).length > 1;

      if (isPresentDuplicatedRequestInQueue && !config.allowMultipleRequests) {
        controller.abort();

        removeRequestUrl(config.url);

        this.loader("off", config.url);
      }

      return {
        ...config,
        signal: controller.signal,
      };
    });

    $axios.interceptors.response.use(
      (response) => {
        removeRequestUrl(response.config.url);

        this.loader("off", response.config.url);

        return response;
      },
      (error) => {
        removeRequestUrl(error.config.url);

        this.loader("off", error.config.url);

        const message = this.getResponseMessage(error.response);

        if (error.message !== "canceled") {
          if (Array.isArray(message) && message.length) {
            message.forEach((code) => this.#notify.error(code));
          } else {
            this.#notify.error(message);
          }
        }

        return Promise.reject(error);
      }
    );
  }

  /**
   * Set the default HTTP request headers
   */
  setHeader(withLanguage = true) {
    const authToken =
      new JwtServiceDefault().getImpersonateToken() || new JwtServiceDefault().getAuthToken();

    Vue.axios.defaults.headers.common = {
      Authorization: `Bearer ${authToken}`,
    };

    if (withLanguage) {
      Vue.axios.defaults.headers.common.Language = new I18nServiceDefault().getActiveLanguage();
    }
  }

  /**
   * Get axios request config (method for redeclaration)
   * @param { Object } settings
   * @returns { Object }
   */
  getRequestConfig(settings) {
    if (settings) {
      return {};
    }
  }

  /**
   * Get message code for global notification
   * @param { Object } response
   * @returns String
   */
  getResponseMessage(response) {
    return response?.data?.message;
  }

  /**
   * Show success notify or set type and message in local storage for display with delay
   * @param { String } message
   * @param { Boolean } withDelay
   */
  showSuccessNotify(message, withDelay) {
    const type = "success";

    withDelay ? this.#notify.setDelayed(type, message) : this.#notify.success(message);
  }

  /**
   * Change loaders state (on | off): redeclare this method
   * @param { String } state
   * @param { String } resource
   */
  loader(state, resource) {
    // eslint-disable-next-line no-console
    if (state === "on") console.log(state, resource);
    // eslint-disable-next-line no-console
    if (state === "off") console.log(state, resource);
  }

  /**
   * Inits axios
   */
  axiosInit() {
    const restApiPrefix = process.env.VUE_APP_REST_API_PREFIX;
    let baseUrl = process.env.VUE_APP_API_DOMAIN;

    const PROTOCOL_BACKSLASHES = "//";

    const [protocol] = baseUrl.split(PROTOCOL_BACKSLASHES);
    const isFullBaseUrl = protocol === "https:" || protocol === "http:";

    baseUrl = baseUrl && isFullBaseUrl ? baseUrl : document.location.origin;

    Vue.use(VueAxios, axios);

    Vue.axios.defaults.baseURL = baseUrl + restApiPrefix;

    this.setAxiosInterceptors(Vue.axios);
  }

  /**
   * Send the GET HTTP request
   * @param { String } resource
   * @param { Object } settings
   * @returns { IDBRequest<IDBValidKey> | Promise<void> }
   */
  get(resource, settings = {}) {
    const {
      withLoader = true,
      withNotify = false,
      delaySuccessNotify = false,
      allowMultipleRequests = false,
      responseType = "",
    } = settings;

    const config = this.getRequestConfig(settings);

    if (allowMultipleRequests) {
      config.allowMultipleRequests = allowMultipleRequests;
    }

    if (responseType.length > 0) {
      config.responseType = responseType;
    }

    if (typeof settings?.params === "object") {
      config.params = { ...settings.params };
    }

    if (withLoader) {
      this.loader("on", resource);
    }

    return Vue.axios.get(resource, config).then((response) => {
      if (withNotify) {
        const message = this.getResponseMessage(response);

        this.showSuccessNotify(message, delaySuccessNotify);
      }

      return response;
    });
  }

  /**
   * Set the POST HTTP request
   * @param { String } resource
   * @param params
   * @param { Object } settings
   * @returns { IDBRequest<IDBValidKey> | Promise<void> }
   */
  post(resource, params = null, settings = {}) {
    const config = this.getRequestConfig(settings);
    const {
      withLoader = true,
      withNotify = false,
      delaySuccessNotify = false,
      allowMultipleRequests = false,
    } = settings;

    if (allowMultipleRequests) {
      config.allowMultipleRequests = allowMultipleRequests;
    }

    if (withLoader) {
      this.loader("on", resource);
    }

    return Vue.axios.post(resource, params, config).then((response) => {
      if (withNotify) {
        const message = this.getResponseMessage(response);

        this.showSuccessNotify(message, delaySuccessNotify);
      }

      return response;
    });
  }

  /**
   * Send the PUT HTTP request
   * @param { String } resource
   * @param params
   * @param { Object } settings
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params = null, settings = {}) {
    const {
      withLoader = true,
      withNotify = false,
      delaySuccessNotify = false,
      allowMultipleRequests = false,
    } = settings;
    const config = this.getRequestConfig(settings);

    if (allowMultipleRequests) {
      config.allowMultipleRequests = allowMultipleRequests;
    }

    if (withLoader) {
      this.loader("on", resource);
    }

    return Vue.axios.put(resource, params, config).then((response) => {
      if (withNotify) {
        const message = this.getResponseMessage(response);

        this.showSuccessNotify(message, delaySuccessNotify);
      }

      return response;
    });
  }

  /**
   * Send the PATCH HTTP request
   * @param { String } resource
   * @param params
   * @param { Object } settings
   * @returns { IDBRequest<IDBValidKey> | Promise<void> }
   */
  patch(resource, params = null, settings = {}) {
    const {
      withLoader = true,
      withNotify = false,
      delaySuccessNotify = false,
      allowMultipleRequests = false,
    } = settings;
    const config = this.getRequestConfig(settings);

    if (allowMultipleRequests) {
      config.allowMultipleRequests = allowMultipleRequests;
    }

    if (withLoader) {
      this.loader("on", resource);
    }

    return Vue.axios.patch(resource, params, config).then((response) => {
      if (withNotify) {
        const message = this.getResponseMessage(response);

        this.showSuccessNotify(message, delaySuccessNotify);
      }

      return response;
    });
  }

  /**
   * Send the DELETE HTTP request
   * @param { String } resource
   * @param { Object } settings
   * @returns { IDBRequest<IDBValidKey> | Promise<void> }
   */
  delete(resource, settings = {}) {
    const {
      withLoader = true,
      withNotify = false,
      delaySuccessNotify = false,
      allowMultipleRequests = false,
    } = settings;
    const config = this.getRequestConfig(settings);

    if (allowMultipleRequests) {
      config.allowMultipleRequests = allowMultipleRequests;
    }

    if (withLoader) {
      this.loader("on", resource);
    }

    return Vue.axios.delete(resource, config).then((response) => {
      if (withNotify) {
        const message = this.getResponseMessage(response);

        this.showSuccessNotify(message, delaySuccessNotify);
      }

      return response;
    });
  }
}
