import gql from "graphql-tag";

export const managersQuery = gql`
  query managers($filters: Filter, $page: Int, $perPage: Int) {
    managers(
      orderBy: { column: "isActive", direction: desc }
      filters: $filters
      page: $page
      first: $perPage
    ) {
      paginatorInfo {
        currentPage
        perPage
        total
        lastPage
      }
      data {
        id
        oneCId
        name
        isActive
        image {
          link
        }
        email
        phone
      }
    }
  }
`;

export const allManagersQuery = gql`
  query allManagers {
    allManagers {
      id
      name
    }
  }
`;

export const allCompaniesQuery = gql`
  query allCompanies {
    allCompanies {
      id
      name
    }
  }
`;

export const allCustomersQuery = gql`
  query allCustomer {
    allCustomer {
      id
      name
    }
  }
`;
