import gql from "graphql-tag";

export const bonusProgramTextQuery = gql`
  query bonusProgramText($id: ID!) {
    bonusProgramText(id: $id) {
      id
      title
      content
    }
  }
`;
