<template>
  <div class="h-full" :class="appClasses" :style="cssVariables">
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { convertHexInRgb, isPWA } from "@/services/_bit/UiService";

export default {
  name: "App",

  computed: {
    ...mapState("user", ["accentColor"]),
    ...mapGetters("user", ["isUserDealer", "userId", "userProfile"]),

    cssVariables() {
      return {
        "--accent-color": convertHexInRgb(this.accentColor),
      };
    },

    appClasses() {
      return {
        "pwa-app": isPWA,
      };
    },
  },

  watch: {
    userId: function (val) {
      if (this.isUserDealer) {
        window.Echo.private(`users.${val}`).notification((notification) => {
          this.setNewNotification(notification);
        });
      }
    },
    userProfile: {
      handler: function (val) {
        if (val.detail?.customer?.id && this.isUserDealer) {
          window.Echo.private(`customers.${val.detail.customer.id}`).listen(
            ".bonus_program.sum_updated",
            (bonuses) => {
              this.setBonuses(bonuses);
            }
          );
        }
      },
    },
  },

  mounted() {
    const isUserDealer = !!Number(localStorage.getItem("isUserDealer"));

    if (isUserDealer) {
      document.addEventListener("click", this.handleGlobalClick);
    }

    setTimeout(() => {
      const preloader = document.getElementById("app-preloader");

      if (preloader) {
        preloader.style.opacity = "0";
        preloader.style.transition = "opacity 0.5s";

        setTimeout(() => {
          preloader.style.display = "none";
        }, 500);
      }
    }, 300);
  },

  beforeDestroy() {
    document.removeEventListener("click", this.handleGlobalClick);
  },

  methods: {
    ...mapActions("user", ["setNewNotification", "setBonuses"]),

    handleGlobalClick(event) {
      window.dataLayer = window.dataLayer || [];
      const target = event.target;
      const tags = [
        "A",
        "BUTTON",
        "IMG",
        "TD",
        "TH",
        "H1",
        "H2",
        "H3",
        "H4",
        "H5",
        "H6",
        "SPAN",
        "DIV",
      ];

      if (tags.includes(target.tagName)) {
        if (
          event.target.classList.contains("our-page-additional-wrapper") ||
          event.target.classList.contains("our-page-wrapper") ||
          event.target.classList.contains("our-page")
        ) {
          return;
        }

        window.dataLayer.push({
          event: "Click",
          clickTex: event.target?.innerText || "",
          clickID: event.target?.id || "",
          clickClasses: event.target?.className || "",
          clickURL: event.target?.href || "",
        });
      }
    },
  },
};
</script>
