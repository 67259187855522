import Vue from "vue";
import App from "./App.vue";
// import VueGtag from "vue-gtag";
import "/src/assets/css/index.pcss";
// import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./plugins/vuelidate";
import "./plugins/vue-tailwind";
import i18n from "@/plugins/vue-i18n";
import ApiService from "@/services/api.service";
import "./mixins/global.mixin";
import "./components/_bit";

// Markdown
import "github-markdown-css/github-markdown.css";
import "highlight.js/styles/github.css";

import hljs from "highlight.js";
import markdown from "markdown-it";
import markdownHighlight from "markdown-it-highlightjs";

const md = markdown({ breaks: true, html: true });

Vue.filter("markdown", function (value) {
  if (!value) return "";

  md.use(markdownHighlight, { hljs });

  return md.render(value);
});

import Echo from "laravel-echo";
import Pusher from "pusher-js";

window.Pusher = Pusher;

window.Echo = new Echo({
  authEndpoint: process.env.VUE_APP_API_DOMAIN + "/broadcasting/auth",
  broadcaster: "pusher",
  key: process.env.VUE_APP_PUSHER_KEY,
  cluster: process.env.VUE_APP_PUSHER_CLUSTER,
  forceTLS: true,
  auth: {
    headers: {
      Authorization: "Bearer " + store.state.auth.token,
    },
  },
});

import Vue2Editor from "vue2-editor";

Vue.use(Vue2Editor);

// Vue apollo
import VueApollo from "vue-apollo";
import apolloClient from "@/plugins/apollo-vue";

Vue.use(VueApollo);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

ApiService.axiosInit();

Vue.config.productionTip = false;

//TODO set 'enabled: false' for Dev server

const appendGaScript = () => {
  const script = document.createElement("script");

  script.text = `
   (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-PNDT3G4M');
`;

  document.head.appendChild(script);
};

if (localStorage.getItem("isUserDealer") === "1") {
  appendGaScript();
}

// Vue.use(VueGtag, {
//   // enabled: !!Number(localStorage.getItem("isUserDealer")),
//   enabled: false,
//   config: {
//     id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
//   },
// });

new Vue({
  i18n,
  router,
  store,
  apolloProvider,
  render: (h) => h(App),
}).$mount("#app");
