import gql from "graphql-tag";

export const bonusProgramLevelQuery = gql`
  query bonusProgramLevel($id: ID!) {
    bonusProgramLevel(id: $id) {
      id
      name
      threshold
      benefits {
        id
        name
        description
        enabled
        type
        value
      }
    }
  }
`;
