<template>
  <OurPage
    class="access-groups-edit"
    :back-route="backRoute"
    :title="$t('title.bonuses.editText')"
    width="md"
  >
    <BonusesForm ref="bonusProgramTextForm" v-model="form" />

    <template #footer-left>
      <OurButton class="button" :text="$t('button.save')" @click="onClickSaveButton" />
      <OurLink :route="backRoute">
        <OurButton class="button" :text="$t('button.back')" variant="secondary" />
      </OurLink>
    </template>
  </OurPage>
</template>

<script>
import apolloMixin from "@/mixins/apollo.mixin";
import { bonusProgramTextQuery } from "@/views/settings/bonuses/program/edit/gql/queries";
import BonusesForm from "@/views/settings/bonuses/_components/BonusesForm.vue";
import { updateBonusProgramTextMutation } from "@/views/settings/bonuses/program/edit/gql/mutations";

export default {
  name: "BaseKnowledgeEdit",

  components: {
    BonusesForm,
  },

  mixins: [apolloMixin],

  data() {
    return {
      backRoute: {
        name: "BonusProgram",
        title: this.$t("title.bonuses._"),
      },
      form: {
        title: "",
        content: "",
      },
    };
  },

  computed: {
    bonusProgramTextId() {
      return this.$route.params.id;
    },
  },

  created() {
    this.getData();
  },

  methods: {
    async getData() {
      const { bonusProgramText } = await this.$get(bonusProgramTextQuery, {
        id: this.bonusProgramTextId,
      });

      if (bonusProgramText) {
        this.form = {
          ...bonusProgramText,
        };
      }
    },

    async onClickSaveButton() {
      this.$refs.bonusProgramTextForm.emitForm();

      if (this.form.isValid) {
        const { updateBonusProgramText } = await this.$post(
          updateBonusProgramTextMutation,
          this.form,
          {
            delaySuccessNotify: true,
          }
        );

        if (updateBonusProgramText.id) await this.$router.push({ name: "BonusProgram" });
      }
    },
  },
};
</script>
