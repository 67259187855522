import gql from "graphql-tag";

export const managerQuery = gql`
  query manager($id: ID!) {
    manager(id: $id) {
      name
      email
      phone
      isActive
      image {
        id
        name
        link
      }
    }
  }
`;
