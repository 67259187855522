import i18n from "@/plugins/vue-i18n";

const Dashboard = () => import(/* webpackChunkName: "dashboard" */ "@/views/dashboard");

const dashboard = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      title: i18n.t("title.dashboard.main"),
      wizardKey: "dashboard",
    },
  },
];

export default dashboard;
