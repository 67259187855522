import gql from "graphql-tag";

export const bonusProgramTextsQuery = gql`
  query bonusProgramTexts {
    bonusProgramTexts {
      id
      title
    }
  }
`;
