<template>
  <OurPage data-cy="users" :title="$t('title.users._')" gray class="users-page">
    <EmployeesList
      :items="employees"
      :pagination-data="paginationData"
      @pageChange="getData"
      @onClickRow="onClickRow"
    />

    <FilterModal :show.sync="isShownFilterModal" />

    <template #header-right>
      <div class="buttons">
        <OurButton
          variant="thirdary"
          filled
          :text="$t('button.filters')"
          @click="onClickFilterButton"
        >
          <OurSvgIcon v-if="isMobileDevice" :src="icons.FilterAltFill" />
        </OurButton>

        <OurLink v-if="getPermission['employee.create']" :route="addRoute">
          <OurButton :text="$t('button.invite')" />
        </OurLink>
      </div>
    </template>
  </OurPage>
</template>

<script>
import FilterModal from "@/views/settings/users/filter/Modal.vue";
import apolloMixin from "@/mixins/apollo.mixin";
import { employeesQuery, rolesQuery } from "@/views/settings/users/index/gql/queries";
import { format as TimeagoFormat, register as TimeagoRegister } from "timeago.js";
import { uk as locale_UA, ru as locale_RU } from "timeago.js/lib/lang";
import I18nService from "@/services/i18n.service";
import EmployeesList from "@/views/settings/users/index/_components/EmployeesList.vue";
import { mapGetters, mapMutations, mapState } from "vuex";
export default {
  components: { EmployeesList, FilterModal },
  mixins: [apolloMixin],
  data() {
    return {
      isShownFilterModal: false,
      employees: [],
      paginationData: {},
    };
  },
  computed: {
    ...mapState("employeeUsers", ["filtersForm", "visit"]),
    ...mapGetters("breakpoint", ["isMobileDevice"]),
    ...mapGetters("employeeUsers", ["getStatus", "getRoleLocalization", "getFiltersOptions"]),
    ...mapGetters("user", ["getPermission"]),

    addRoute() {
      return { name: "UsersAdd" };
    },

    icons: () => ({
      FilterAltFill: require("@material-symbols/svg-400/outlined/filter_alt-fill.svg"),
    }),
  },
  watch: {
    filtersForm: {
      handler: "onChangeFiltersForm",
      deep: true,
    },
  },
  async created() {
    TimeagoRegister("ua", locale_UA);
    TimeagoRegister("ru", locale_RU);
    // await this.getData();
    await this.getRoles();
  },
  methods: {
    ...mapMutations("employeeUsers", ["SET_ROLES"]),

    async getRoles() {
      const { roles } = await this.$get(rolesQuery);

      this.SET_ROLES(roles);
    },

    onClickFilterButton() {
      this.isShownFilterModal = true;
    },
    onClickRow(id) {
      this.$router.push({ name: "UsersEdit", params: { id } });
    },
    onChangeFiltersForm() {
      this.getData();
    },
    async getData(page) {
      const activeLanguage = I18nService.getActiveLanguage();

      if (page && page === this.paginationData.currentPage) return;

      const filtersOptions = this.getFiltersOptions();

      const { employees } = await this.$get(employeesQuery, { page, filtersOptions });

      if (employees) {
        this.employees = employees.data.map((employee) => {
          const { id, email, firstName, lastName, roles, detail, lastActivityAt } = employee;

          const { status } = detail;

          const employeeStatus = this.getStatus(status);
          const role = roles[0].name;
          const timestampInMilliseconds = lastActivityAt * 1000;
          const { just, online, notEnter } = this.visit;
          let date = lastActivityAt
            ? TimeagoFormat(timestampInMilliseconds, activeLanguage)
            : notEnter;

          if (date === just) date = online;

          return {
            id,
            user: {
              primaryRow: `${lastName} ${firstName}`,
              secondaryRow: role,
            },
            contacts: {
              email,
            },
            visit: date,
            status: employeeStatus,
            page: "UsersEdit",
          };
        });

        this.paginationData = employees.paginatorInfo;
      }
    },
  },
};
</script>

<style scoped lang="postcss">
.users-page {
  .buttons {
    @apply flex space-x-4;
  }
}
</style>
