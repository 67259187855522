<template>
  <div class="levels-form">
    <OurGroup>
      <OurInput
        v-model="form.name"
        :label="$t('page.catalog.name')"
        :placeholder="$t('placeholder.baseKnowledgeName')"
        :error="nameError"
      />
      <OurInput
        v-model="form.threshold"
        only-digits
        :label="$t('title.bonuses.threshold')"
        :placeholder="$t('placeholder.threshold')"
        :error="thresholdError"
      />
      <div v-for="benefit in form.benefits" :key="benefit.id">
        <div class="align-center mb-2 flex justify-between gap-8">
          <span class="text-md">
            {{ benefit.name }}
          </span>
          <div>
            <OurSwitch
              v-model="benefit.enabled"
              color="green"
              name="condition"
              size="md"
              @on-click-change-switch="removeError(benefit.id)"
            />
          </div>
        </div>
        <OurInput
          v-if="benefit.type === 'percentage' && benefit.enabled"
          v-model="benefit.value"
          from-one-to-hundred
          :label="benefit.name"
          :placeholder="benefit.name"
          :error="setError(benefit.id) ? $t('validation.fieldLength') : ''"
          @input="removeError(benefit.id)"
        />
        <OurInput
          v-if="(benefit.type === 'days' || benefit.type === 'pieces') && benefit.enabled"
          v-model="benefit.value"
          :class="{ 'error-input': setError(benefit.id) }"
          only-digits
          :label="benefit.name"
          :placeholder="benefit.name"
          :error="setError(benefit.id) ? $t('validation.required') : ''"
          @input="removeError(benefit.id)"
        />
      </div>
    </OurGroup>
  </div>
</template>

<script>
import { maxLength, required } from "vuelidate/lib/validators";
import ValidationService from "@/services/validation.service";

const { TEXT_MAX_LENGTH } = ValidationService;

export default {
  name: "LevelsForm",

  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      form: {
        name: "",
        threshold: 0,
        benefits: [],
      },
      errors: [],
    };
  },

  validations: {
    form: {
      name: { required, maxLength: maxLength(TEXT_MAX_LENGTH) },
      threshold: { required },
    },
  },

  computed: {
    thresholdError() {
      const isDirty = this.$v.form.threshold.$dirty;
      const isFilled = this.$v.form.threshold.required;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      }

      return error;
    },

    nameError() {
      const isDirty = this.$v.form.name.$dirty;
      const isFilled = this.$v.form.name.required;

      const maxLength = this.$v.form.name.maxLength;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      } else if (isDirty && !maxLength) {
        error = this.$t("validation.textMaxLength", {
          length: TEXT_MAX_LENGTH,
        });
      }

      return error;
    },
  },

  watch: {
    value: {
      handler: "getForm",
      deep: true,
    },
  },

  methods: {
    getForm() {
      if (this.value) {
        this.form = { ...this.value };
      }
    },

    setError(id) {
      return this.errors.find((errorId) => errorId === id);
    },

    removeError(id) {
      if (this.errors.includes(id)) {
        this.errors = this.errors.filter((errorId) => errorId !== id);
      }
    },

    emitForm() {
      if (this.$v.$invalid) {
        this.$v.$touch();

        return;
      }

      this.form.benefits.filter((item) => {
        if (
          item.enabled === true &&
          (item.type === "percentage" || item.type === "days" || item.type === "pieces") &&
          !item.value
        ) {
          this.errors.push(item.id);
        } else if (
          item.enabled === true &&
          item.type === "percentage" &&
          parseFloat(item.value) > 100
        ) {
          this.errors.push(item.id);
        }
      });

      if (this.errors.length) return;

      this.form.isValid = true;
      this.$emit("input", this.form);
    },
  },
};
</script>

<style scoped>
.levels-form:deep .label {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.label {
  @apply absolute font-normal text-gray-500;
}
.input {
  @apply font-normal text-gray-900;
  @apply rounded-lg border-none bg-white shadow-none;
  @apply w-full px-4;

  &-wrapper {
    @apply relative w-full;
  }

  &:focus {
    @apply ring-0;
  }

  &:read-only {
    @apply border-gray-300 ring-0;
  }

  &::placeholder {
    @apply font-normal text-gray-400;
  }

  &:disabled {
    @apply text-gray-900;
    @apply border-gray-100 bg-gray-100 opacity-100;
    @apply cursor-default;
  }

  /* this trick replace characters to dots same as in input type="password" */
  &-password-emulation {
    @apply pr-11 leading-5 tracking-widest;
    font-family: text-security-disc, serif;
    -webkit-text-security: disc;
  }

  &-block {
    @apply relative flex;
    @apply rounded-lg border border-solid border-gray-300 bg-white !opacity-100;

    &:hover {
      @apply border-gray-400;
    }

    &:focus-within {
      @apply border-gray-500 ring-4 ring-gray-600 ring-opacity-15;
    }
  }

  &-right-slot {
    @apply block;
    @apply flex w-full items-center justify-end;
    @apply whitespace-nowrap pr-4;
  }

  &-with-icon-padding {
    @apply pr-11;
  }
}
</style>
