import Vue from "vue";
import Vuex from "vuex";

// Common modules
import layout from "@/layouts/_bit/admin-mono/store";
import loader from "@/layouts/_bit/_components/Loader/store";
import loaderTop from "@/layouts/_bit/_components/LoaderTop/store";
import breakpoint from "@/layouts/_bit/_components/Viewport/store";
import auth from "@/store/modules/auth.module";
import user from "@/store/modules/user.module";
// Project related modules
import currencies from "@/store/modules/currencies.module";
import fleaMarket from "@/store/modules/fleaMarket.module";
import leads from "@/store/modules/leads.module";
import priceList from "@/store/modules/priceList.module";
import product from "@/store/modules/product.module";
import regions from "@/store/modules/regions.module";
import serviceCases from "@/store/modules/serviceCases.module";
import settings from "@/store/modules/settings.module";
import dealerUsers from "@/store/modules/dealerUsers.module";
import dealerCompany from "@/store/modules/dealerCompany.module";
import employeeUsers from "@/store/modules/employeeUsers.module";
import accessGroups from "@/store/modules/accessGroups.module";
import orders from "@/store/modules/orders.module";
import knowledgeBase from "@/store/modules/knowledgeBase.module";
import customers from "@/store/modules/customers.module";
import managers from "@/store/modules/managers.module";
import newApplications from "@/store/modules/newApplications.module";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    layout,
    loader,
    loaderTop,
    breakpoint,
    auth,
    user,
    currencies,
    fleaMarket,
    leads,
    priceList,
    product,
    regions,
    serviceCases,
    settings,
    dealerUsers,
    dealerCompany,
    employeeUsers,
    accessGroups,
    orders,
    knowledgeBase,
    customers,
    newApplications,
    managers,
  },
});
