import Vue from "vue";
import VueI18n from "vue-i18n";
import ua from "@/i18n/uk.yaml";

Vue.use(VueI18n);

let messages = {};

messages = { ...messages, ua };

const lang = localStorage.getItem("language") || "ua";

const pluralizationRules = (value, plurals) => {
  let pluralIndex = 0;

  if (value) {
    const remainder = value % 10;
    const teen = value > 10 && value < 20;
    const endsWithOne = remainder === 1;

    if (plurals < 4) {
      pluralIndex = !teen && endsWithOne ? 1 : 2;
    } else {
      pluralIndex = 3;
      if (!teen && endsWithOne) pluralIndex = 1;
      if (!teen && remainder >= 2 && remainder <= 4) pluralIndex = 2;
    }
  }

  return pluralIndex;
};

const i18n = new VueI18n({
  locale: lang,
  fallbackLocale: "ua",
  messages,
  pluralizationRules: {
    ua: pluralizationRules,
  },
});

export default i18n;
