import I18nServiceDefault from "@/services/_bit/I18nService";
import { enGB, ru, uk } from "date-fns/locale";
import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfTomorrow,
  format,
  getUnixTime,
  isToday,
  isTomorrow,
  isYesterday,
  minutesToSeconds,
  startOfDay,
  startOfMonth,
  subDays,
} from "date-fns";

const { getActiveLanguage } = new I18nServiceDefault();

export default class DateServiceDefault {
  #languages = {
    en: enGB,
    ru: ru,
    ua: uk,
  };

  #settings = {
    yearFormat: "y",
    monthFormat: "MMMM",
    dayFormat: "EEEE",
    dateNumberFormat: "dd",
    hourFormat: "HH",
    minuteFormat: "mm",
    secondFormat: "ss",
  };

  TOMORROW_TIMESTAMP = getUnixTime(endOfTomorrow());

  constructor(languages = null) {
    this.#languages = languages || this.#languages;
  }

  getDifferenceBetweenTimeZone(date) {
    const diffInMinutesFrom = Math.abs(startOfDay(Number(date?.from)).getTimezoneOffset());
    const diffInMinutesTo = Math.abs(endOfDay(Number(date?.to)).getTimezoneOffset());

    return {
      from: minutesToSeconds(diffInMinutesFrom),
      to: minutesToSeconds(diffInMinutesTo),
    };
  }

  formatDate(date, settings) {
    const mergedSettings = {
      ...this.#settings,
      ...settings,
    };
    const {
      dayFormat,
      monthFormat,
      yearFormat,
      dateNumberFormat,
      hourFormat,
      minuteFormat,
      i18n,
      withTime,
    } = mergedSettings;
    const locale = this.#languages[getActiveLanguage()];

    const day = format(date, dayFormat, { locale });
    const month = format(date, monthFormat, { locale }).split(".").join(""); //Removes dot from Ukr and Rus localization;
    const year = format(date, yearFormat);
    const dateNumber = format(date, dateNumberFormat);
    const hours = format(date, hourFormat);
    const minutes = format(date, minuteFormat);

    let formattedDate = `${day}, ${dateNumber} ${month} ${year}`;

    if (isYesterday(date)) {
      formattedDate = `${i18n.yesterday}, ${dateNumber} ${month} ${year}`;
    }

    if (isToday(date)) {
      formattedDate = `${i18n.today}, ${dateNumber} ${month} ${year}`;
    }

    if (isTomorrow(date)) {
      formattedDate = `${i18n.tomorrow}, ${dateNumber} ${month} ${year}`;
    }

    if (withTime) {
      formattedDate = `${formattedDate} – ${hours}:${minutes}`;
    }

    return formattedDate;
  }

  dateConverter(timestamp, type = "date") {
    const locale = this.#languages[getActiveLanguage()];
    const date = new Date(timestamp * 1000);
    let options = "";

    if (type.includes("date")) {
      options = "dd.MM.yyyy";
    }

    if (type.includes("time")) {
      options = "HH:mm:ss";
    }

    if (type.includes("datetime")) {
      options = "HH:mm dd.MM.yyyy";
    }

    if (type.includes("dateWithFullMonth")) {
      options = "dd MMMM yyyy";
    }

    if (type.includes("dateWithFullMonthAndTime")) {
      options = "dd MMMM yyyy HH:mm";
    }

    return format(date, options, { locale });
  }

  thirtyDaysAgo() {
    const date = new Date();
    const thirtyDaysAgo = subDays(date, 30);

    return getUnixTime(startOfDay(thirtyDaysAgo));
  }

  nextTwoWeek() {
    const date = new Date();
    const nextTwoWeek = addDays(date, 14);

    return getUnixTime(endOfDay(nextTwoWeek));
  }

  currentMonth() {
    const currentMonth = addMonths(new Date(), 0);

    const firstDayOfMonth = getUnixTime(startOfMonth(currentMonth));
    const lastDayOfMonth = getUnixTime(endOfMonth(currentMonth));

    return { firstDayOfMonth, lastDayOfMonth };
  }
}
