export default {
  data() {
    return {
      observer: null,
    };
  },

  async mounted() {
    this.observer = new IntersectionObserver(
      (entries) => {
        this.handleIntersect(entries[0]);
      },
      { root: null, threshold: 0 }
    );

    if (this.$refs.trigger) {
      this.observer.observe(this.$refs?.trigger);
    }
  },

  beforeDestroy() {
    this.observer?.disconnect();
  },

  methods: {
    handleIntersect(entry) {
      if (entry.isIntersecting && this.observerCallback) {
        this.observerCallback();
      }
    },
  },
};
