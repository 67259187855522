<script>
import FilterModal from "@/views/settings/managers/filter/Modal.vue";
import apolloMixin from "@/mixins/apollo.mixin";
import { mapGetters, mapMutations, mapState } from "vuex";
import ManagersList from "@/views/settings/managers/index/_components/ManagersList.vue";
import UsersList from "@/views/settings/dealer-users/index/_components/UsersList.vue";
import {
  allCompaniesQuery,
  allManagersQuery,
  managersQuery,
} from "@/views/settings/managers/index/gql/queries";
import { allCustomersQuery } from "@/views/settings/dealer-company/index/gql/queries";

export default {
  name: "Managers",
  components: { UsersList, ManagersList, FilterModal },
  mixins: [apolloMixin],
  data() {
    return {
      managers: [],
      paginationData: {},
      isShownFilterModal: false,
    };
  },

  computed: {
    ...mapState("managers", ["filtersForm"]),
    ...mapGetters("breakpoint", ["isMobileDevice"]),
    ...mapGetters("managers", ["getStatus", "getFiltersOptions"]),

    icons: () => ({
      FilterAltFill: require("@material-symbols/svg-400/outlined/filter_alt-fill.svg"),
    }),
  },

  watch: {
    filtersForm: {
      handler: "onChangeFiltersForm",
      deep: true,
    },
  },

  created() {
    this.getAllCustomers();
    this.getAllManagers();
    this.getAllCompanies();
  },

  methods: {
    ...mapMutations("managers", ["SET_MANAGERS", "SET_COMPANIES", "SET_CUSTOMERS"]),

    onClickFilterButton() {
      this.isShownFilterModal = true;
    },

    onClickRow(id) {
      this.$router.push({ name: "ManagersEdit", params: { id } });
    },

    async getAllManagers() {
      const { allManagers } = await this.$get(allManagersQuery);

      this.SET_MANAGERS(allManagers);
    },

    async getAllCompanies() {
      const { allCompanies } = await this.$get(allCompaniesQuery);

      if (allCompanies) {
        this.SET_COMPANIES(allCompanies);
      }
    },

    async getAllCustomers() {
      const { allCustomer } = await this.$get(allCustomersQuery);

      this.SET_CUSTOMERS(allCustomer);
    },

    onChangeFiltersForm() {
      this.getData();
    },

    async getData(page) {
      if (page && page === this.paginationData.currentPage) return;

      const filtersOptions = this.getFiltersOptions();

      const { managers } = await this.$get(managersQuery, { page, filtersOptions });

      this.managers = managers.data.map((manager) => {
        const { id, email, phone, name, oneCId, isActive, image } = manager;
        const img = image?.link ? image.link : "";

        return {
          image: {
            image: img,
            name: name,
          },
          id,
          user: {
            primaryRow: name,
            secondaryRow: oneCId,
          },
          contacts: {
            phone,
            email,
          },
          status: this.getStatus(isActive),
        };
      });

      this.paginationData = managers.paginatorInfo;
    },
  },
};
</script>

<template>
  <OurPage :title="$t('title.managers._')" gray>
    <ManagersList
      :items="managers"
      :pagination-data="paginationData"
      @pageChange="getData"
      @onClickRow="onClickRow"
    />

    <FilterModal :show.sync="isShownFilterModal" />

    <template #header-right>
      <div class="buttons">
        <OurButton
          variant="thirdary"
          filled
          :text="$t('button.filters')"
          @click="onClickFilterButton"
        >
          <OurSvgIcon v-if="isMobileDevice" :src="icons.FilterAltFill" />
        </OurButton>
      </div>
    </template>
  </OurPage>
</template>

<style scoped lang="postcss"></style>
