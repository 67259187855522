<template>
  <div class="access-groups-form">
    <OurGroup>
      <OurInput
        v-model="form.title"
        :label="$t('page.catalog.name')"
        :placeholder="$t('placeholder.title')"
        :error="titleError"
      />

      <vue-editor
        v-model="form.content"
        class="custom-vue__editor"
        :placeholder="'Введіть текст'"
        :editor-toolbar="customToolbar"
        use-custom-image-handler
        @image-added="handleImageAdded"
      />
    </OurGroup>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import ValidationService from "@/services/validation.service";
import { uploadFilesMutation } from "@/views/settings/base-knowledge/chapter/add/gql/mutations";
import apolloMixin from "@/mixins/apollo.mixin";

const { TEXT_MAX_LENGTH } = ValidationService;

export default {
  name: "BonusesForm",
  mixins: [apolloMixin],
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      form: {
        title: "",
        content: "",
      },
      contentImages: [],
      customToolbar: [
        [
          {
            header: [false, 1, 2, 3, 4, 5, 6],
          },
        ],
        ["bold", "italic", "underline", "strike"],
        [
          {
            align: "",
          },
          {
            align: "center",
          },
          {
            align: "right",
          },
          {
            align: "justify",
          },
        ],
        ["image", "blockquote", "code-block"],
        [
          {
            list: "ordered",
          },
          {
            list: "bullet",
          },
        ],
        ["link", "video"],
      ],
    };
  },

  validations: {
    form: {
      title: { required, maxLength: maxLength(TEXT_MAX_LENGTH) },
    },
  },

  computed: {
    titleError() {
      const isDirty = this.$v.form.title.$dirty;
      const isFilled = this.$v.form.title.required;

      const maxLength = this.$v.form.title.maxLength;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      } else if (isDirty && !maxLength) {
        error = this.$t("validation.textMaxLength", {
          length: TEXT_MAX_LENGTH,
        });
      }

      return error;
    },
  },

  watch: {
    value: {
      handler: "getForm",
      deep: true,
    },
  },

  methods: {
    getForm() {
      if (this.value) {
        this.form = { ...this.value };
      }
    },

    emitForm() {
      if (this.$v.$invalid) {
        this.$v.$touch();

        return;
      }

      this.form.isValid = true;
      this.form.contentImages = [...this.contentImages];
      this.$emit("input", this.form);
    },

    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      const { uploadFiles: files } = await this.$post(
        uploadFilesMutation,
        { files: file },
        { withNotify: true }
      );

      this.contentImages.push(files[0].id);

      if (files) {
        const url = files[0].link;

        Editor.insertEmbed(cursorLocation, "image", url);
        resetUploader();
      }
    },
  },
};
</script>
